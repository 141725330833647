.chiffres {
  position: relative;
  height: auto;
  background-color: var(--dark-secondary);
  transition: all .5s;
  text-align: center;
  padding: 1% 0% 2% 0;
}

/******************************/

.title-container {
  width: 100%;
  height: auto;
}

.PLASSYS-logo {
  position: relative;
  width: 15vw;
  height: auto;
  margin: 0 auto;
  filter: var(--dark-img);
  transition: all .5s;
}


/******************************/

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 0.5fr);
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 10%;
  margin-bottom: 0;
}

.grid-item-chiffre {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--dark-text);
  transition: all .5s;
  font-family: "JakartaExtraBold", sans-serif;
  font-size: 4em; 
  margin: 0 auto;
  padding: 0%;
}

.grid-item-img {
  width: 25%;
  height: auto;
  margin: 0 auto;
  padding: 0%;  
}

.grid-item-text {
  width: 100%;
  height: auto;
  font-family: "JakartaSemiBold", sans-serif;
  font-size: 1.8em;
  color : var(--dark-text2);
  transition: all .5s;
  margin: 0 auto;
  padding: 0%;
}

.position-1 {
  grid-column: 1;
  grid-row: 1;
}

.position-2 {
  grid-column: 2;
  grid-row: 1;
}

.position-3 {
  grid-column: 3;
  grid-row: 1;
  width: 28%;
}

.position-4 {
  grid-column: 4;
  grid-row: 1;
  width: 22%;
}

.position-5 {
  grid-column: 1;
  grid-row: 2;
}

.position-6 {
  grid-column: 2;
  grid-row: 2;
}

.position-7 {
  grid-column: 3;
  grid-row: 2;
}

.position-8 {
  grid-column: 4;
  grid-row: 2;
}

.position-9 {
  grid-column: 1;
  grid-row: 3;
}

.position-10 {
  grid-column: 2;
  grid-row: 3;
}

.position-11 {
  grid-column: 3;
  grid-row: 3;
}

.position-12 {
  grid-column: 4;
  grid-row: 3;
}

/******************************/

.icones-container {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  gap: 10em;
  margin : 0 auto;
  margin-top: 2%;
  max-width: 500px;
  min-width: 300px;
}

.icones-container img {
  width: 5em;
  height: auto;
}

/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {
  .chiffres {
    display: none;
  }
}