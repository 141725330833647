/* Presentation in Home Page */

.pres-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin: 0 auto;
    flex-shrink: 0;
    background-color: var(--dark-secondary);
    transition: all .5s;
    z-index: 2;
    overflow: hidden;
    padding: 2rem 0;
}

.pres-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../../../../public/img/logo/logo2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size:auto;
    filter: blur(5px);
    opacity: 0.04;
    z-index: 1;
    overflow: hidden;
    filter: var(--dark-img);
}

.pres-title {
    margin-top: 2%;
    font-family: "JakartaExtraBold";
    color: var(--dark-text);
    transition: all .5s;
    z-index: 2;

}

.pres-text {
    text-align: center;
    font-family: "JakartaItalic";
    color: var(--dark-text);
    transition: all .5s;
    font-size: 18px;
    font-weight: 300;
    margin: 0 auto;
    margin-bottom: 2%;
    padding: 0 1rem;
    width: 70%;
    line-height: 2rem;
    z-index: 2;
    
}

/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {
    .pres-text {
        width: 90%;
        font-size: 16px;
        line-height: 1.7rem;
    }
  
}