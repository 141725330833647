.cookie-popup {
    position: fixed;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    width: 90%;
    background-color: white;
    border: 2px solid var(--dark-extra);
    color: black;
    padding: 1%;
    padding-left: 3%;
    padding-right: 3%;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
    animation: slide-up 0.5s ease-out;
    z-index: 1000;
    font-family: JakartaRegular, sans-serif;

}

.cookie-popup p {
    margin: 0;
    max-width: 80%;
}

.cookie-popup div {
  max-width: 70%;
}

.cookie-popup button {
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
}

.btn-accept {
    background-color: #4CAF50;
}

.btn-accept:hover {
    background-color: #45a049;
}

.btn-reject {
    background-color: #f44336;
}

.btn-reject:hover {
    background-color: #da190b;
}


.button-container-popup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}


/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {
    .cookie-popup {
        flex-direction: column;
        padding: 10px;
        font-size: 12px;
    }

    .cookie-popup div {
        max-width: 100%;
        margin-bottom: 10px;
    }

    .cookie-popup button {
        padding: 10px 20px;
        font-size: 14px;
    }

    .cookie-popup p {
        max-width: 100%;
    }
}