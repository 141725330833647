.noResults {
    transition: all 0.5s;
}

.devise-productCat {
    margin-top: 2vh;
    font-family: "JakartaExtraBold", sans-serif;
    color: var(--dark-text);
    transition: all 0.5s;
    font-size: 22px;
}

/****************************************************/
/*intro*/

.intro-container {
    background-color: var(--dark-secondary);
    border-radius: 1rem;
    color: var(--dark-text);
    overflow: hidden;
    transition: all .5s;
    max-width: 78vw;
    margin: 2rem auto;
}

.intro-top {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-left: 2rem;
    height: 100%;
    gap: 2rem;
}

/************************************/
/*intro*/

.intro-top-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    flex: 1;
}

.intro-top-left-titles {
    width: 100%;
    font-family: "JakartaExtraBold";
}

.intro-top-left-title {
    font-size: 2rem;
    margin: 0;
    position: relative;
    display: inline-block;
}

.intro-top-left-title span {
    font-size: 0.8rem;
    position: absolute;
    top: 0;
}

.intro-top-left-subtitle {
    font-size: 1.2rem;
    padding: 0;
}



/****************************/
/*intro right top*/
.intro-top-right {
    position: relative;
    /*background-image: in props of the component*/
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 50%;
    min-width: 50%;
    min-height: 35vh;
    flex: 1;
}

.intro-top-right::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-image: linear-gradient(to right, var(--dark-secondary), transparent);
    background-repeat: no-repeat;
    background-size: 400px 100%;
    background-position: left;
    transition: all .5s;
}

/****************************/

.list a {
    color: var(--dark-text3);
    font-family: "JakartaExtraBold";
    transition: all 0.5s;
}

.list a:hover {
    color: var(--dark-text);
}

/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {
    .devise-productCat {
        margin: 2vh;
        font-size: 18px;
    }

    .intro-container {
        width: 100%;
        max-width: 90%;
        margin-top: 3vh;
    }

    .intro-top {
        flex-direction: column;
        margin: 0;
        gap: 0;
    }

    .intro-top-left {
        padding: 10px;
    }

    .intro-top-left-titles {
        margin-top: 0.5rem;
        text-align: center;
    }

    .intro-top-left-title {
        font-size: 1.9rem;
    }

    .intro-top-left-subtitle {
        font-size: 1rem;
    }

    .intro-top-right {
        max-width: 100%;
        min-width: 100%;
        min-height: 35vh;
    }

    .intro-top-right::before {
        background-image: linear-gradient(to top, var(--dark-secondary), transparent), linear-gradient(to bottom, var(--dark-secondary), transparent);
        background-repeat: no-repeat;
        background-size: 100% 100px, 100% 100px;
        background-position: bottom, top;
    }

    .list ul {
        padding-left: 5vw;
    }

}