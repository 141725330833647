.accordion-item {
    background-color: var(--dark-secondary) !important;
    width: 100%;
    transition: all 0.5s;
    border-radius: 10px !important;
    color: var(--dark-text) !important;
}

.accordion-item svg {
    color: var(--dark-text) !important;
}

.accordion-item:hover {
    background-color: var(--dark-secondary) !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    color: var(--dark-text);
}

.accordion-item .Mui-expanded {
    background-color: var(--dark-secondary) !important;
    color: var(--dark-text);
}

.accordion-item .MuiCollapse-vertical {
    background-color: var(--dark-secondary) !important;
    transition: all 0.5s;
}

.accordion-title {
    font-family: "JakartaExtraBold", sans-serif;
    color: var(--dark-text);
    transition: all 0.5;
    font-size: 1.5em;

}

.accordion-photo-container {
    width: 100%;
    transition: all 0.5s;
    padding: 2.5%;
    padding-top: 1%;
    border-radius: 10px;
    box-sizing: border-box !important;
}

.accordion-photo-container img {
    border-radius: 10px;
    transition: all 0.5s;
}

.accordion-photo-container img:hover {
    cursor: pointer;
    scale: 1.03;
    transition: all 0.5s;
    filter: brightness(1.1);
}

/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {

    .accordion-details-text {
        font-size: 16px;
    }

    .accordion-photo-container {
        width: 100%;
        max-width: 100%;
        margin-top: 20px;
        box-sizing: border-box;
        margin: 0 !important;
        padding: 0 !important;
    }

    .accordion-action-container a {
        margin-right: 5%;
        margin-bottom: 5%;
    }

}