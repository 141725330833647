/*Theme*/

[data-theme='light'] {
  --dark-main: #f5f5f5;
  --dark-secondary: #d0d0d0;
  --dark-scroll: #5050e5;
  --dark-hamburger: #b52a7d;
  --dark-text: #333;
  --dark-text2: #b52a7d;
  --dark-text3: #393e42;
  --dark-text4: #282828;
  --dark-point: #b52a7d;
  --dark-product: #d0d0d0;
  --dark-img: brightness(40%);
  --dark-img2: invert(0);
  --dark-sitemap: #d0d0d0;
  --dark-oddTable: #d9d9d9;
  --dark-extra: #d0d0d0;
  --dark-extra2: #b8b8b8;
  --dark-extra3: #f5f5f5;
  --dark-download: #b52a7d;
  --dark-contrasted:rgba(0, 0, 0, 0.1);
  --dark-accordion: #e0e0e0;
  --dark-accordion-hover : #d0d0d0;
  --dark-barnav : #b52a7d;
  --dark-barnav-dropdown-selected: #d0d0d0;
  --dark-map: #3335;
  --dark-search: #fff;
  --dark-important: #b52a7d
}

[data-theme='dark'] {
  --dark-main: #001f3f;
  --dark-secondary: #001124;
  --dark-scroll: #5050e5;
  --dark-hamburger: #d9d9d9;
  --dark-text: #fff;
  --dark-text2: #b52a7d;
  --dark-text3: #4C6278;
  --dark-text4: #282828;
  --dark-point: #ffffff;
  --dark-product: #fff;
  --dark-img: brightness(1);
  --dark-img2: invert(1);
  --dark-sitemap: #fff ;
  --dark-oddTable: #ffffff;
  --dark-extra: #5050e5;
  --dark-extra2: #001124;
  --dark-extra3: #5050e5;
  --dark-download: #5050e5;
  --dark-contrasted:rgba(255, 255, 255, 0.1);
  --dark-accordion: #f5f5f5;
  --dark-accordion-hover : #e0e0e0;
  --dark-barnav : #b52a7d;
  --dark-barnav-dropdown-selected: #b52a7d;
  --dark-map: #fff;
  --dark-search: #a9a9a9;
  --dark-important: #ff80bf
}

/***********************************/
/* Grille de débogage */
/* body::after {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
  background: url(data:image/svg+xml,%3Csvg%20width%3D%2280%22%20height%3D%2280%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cg%20stroke%3D%22%23ff0000%22%20stroke-width%3D%221%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M40%200v80M0%2040h80%22/%3E%3C/g%3E%3C/svg%3E) 0 0 / 100% 100% repeat;
}
body::before {
  content: '';
  display: block;
  position: fixed;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: red;
  pointer-events: none;
  z-index: 10000;
}
body::after {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  background: red;
  pointer-events: none;
  z-index: 10000;
} */

/***********************************/
/*Scroll bar*/
::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}


::-webkit-scrollbar-track {
  background: var(--dark-main);
  
  
}

::-webkit-scrollbar-thumb {
  background: var(--dark-scroll);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b1b1b1;
}



/***********************************/
/* Global */
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-main);
  transition: all 0.5s;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.Content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 72px; /*Need to adapt to the navbar + border height*/
  height: 100%;
  width: 100%;
}

b {
  font-family: 'JakartaBold', sans-serif;
}

/***********************************/
/*Carousel*/

.nuka-carousel-next, .nuka-carousel-prev {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.5s;
  filter: var(--dark-img2);
  object-fit: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

}

.keepwhite{ 
  filter: invert(1);
}

.nuka-carousel-prev:hover,
.nuka-carousel-next:hover {
  transform: scale(1.05);
  opacity: 1;
}

.nuka-carousel-prev:active, 
.nuka-carousel-next:active{
  transform: scale(0.9);
  transition: all 0.1s;
}

.nuka-carousel-prev {
  background-image: url('./../public/img/icones/i-arrow-left.png');
}

.nuka-carousel-next {
  background-image: url('./../public/img/icones/i-arrow-right.png');
}

/***********************************/
/* loader */
.load-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9000;
}

.load-container div {
  position: absolute;
  width: 90px;
  height: 51px;
}

.load-container div:nth-of-type(2) {
  transform: rotate(60deg)
}

.load-container div:nth-of-type(3) {
  transform: rotate(-60deg)
}

.load-container div div {
  width: 100%;
  height: 100%;
  position: relative
}

.load-container div div span {
  position: absolute;
  width: 6px;
  height: 0%;
  background: #b52a7d;
  z-index: 999999;
}

.e1 {
  left: 0;
  animation: load1 7.2s ease infinite;
}

.e2 {
  right: 0;
  animation: load2 7.2s ease .4s infinite;
}

.e3 {
  right: 0;
  animation: load3 7.2s ease .8s infinite;
}

.e4 {
  right: 0;
  animation: load4 7.2s ease 1.2s infinite;
}

.e5 {
  left: 0;
  animation: load5 7.2s ease 1.6s infinite;
}

.e6 {
  left: 0;
  animation: load6 7.2s ease 2s infinite;
}

@keyframes load1 {
  0% {
    bottom: 0;
    height: 0
  }

  6.944444444% {
    bottom: 0;
    height: 100%
  }

  50% {
    top: 0;
    height: 100%
  }

  59.944444433% {
    top: 0;
    height: 0
  }
}

@keyframes load2 {
  0% {
    top: 0;
    height: 0
  }

  6.944444444% {
    top: 0;
    height: 100%
  }

  50% {
    bottom: 0;
    height: 100%
  }

  59.944444433% {
    bottom: 0;
    height: 0
  }
}

@keyframes load3 {
  0% {
    top: 0;
    height: 0
  }

  6.944444444% {
    top: 0;
    height: 100%
  }

  50% {
    bottom: 0;
    height: 100%
  }

  59.94444443% {
    bottom: 0;
    height: 0
  }
}

@keyframes load4 {
  0% {
    top: 0;
    height: 0
  }

  6.944444444% {
    top: 0;
    height: 100%
  }

  50% {
    bottom: 0;
    height: 100%
  }

  59.94444443% {
    bottom: 0;
    height: 0
  }
}

@keyframes load5 {
  0% {
    bottom: 0;
    height: 0
  }

  6.944444444% {
    bottom: 0;
    height: 100%
  }

  50% {
    top: 0;
    height: 100%
  }

  59.94444443% {
    top: 0;
    height: 0
  }
}

@keyframes load6 {
  0% {
    bottom: 0;
    height: 0
  }

  6.944444444% {
    bottom: 0;
    height: 100%
  }

  50% {
    top: 0;
    height: 100%
  }

  59.94444443% {
    top: 0;
    height: 0
  }
}
  
/***********************************/
/*back button*/

.backbutton-container{
  display: flex;
  justify-content: left;
}

.backbutton {
  margin-left: 4%;
  position: relative;
  margin-top: 2%;
  background-color: var(--dark-extra);
  color: var(--dark-text);
  transition: all .5s;
  width: 10.5em;
  height: 3.9em;
  border: var(--dark-extra) 0.2em solid;
  border-radius: 10px;
}

.backbutton:hover {
  background-color: var(--dark-extra2);
  transition: all .5s;
  cursor: pointer;
}

.backbutton svg {
  width: 2em;
  margin: -0.4em 0.8em 1em;
  position: absolute;
  display: flex;
  transition: all 0.4s ease;
  color: var(--dark-text);
  transition: all .5s;
  transform: rotate(180deg);
}

.backbutton:hover svg {
  transform: translateX(-5px) rotate(180deg);
}

.backbuttontext {
  margin: -4px 3em;
  font-size: 16px;
  font-family: "JakartaRegular", sans-serif;
}

/***********************************/
/* inputsearch */

.noResults {
  width: 100%;
  color: var(--dark-text);
  font-size: 1.5em;
  font-family: "JakartaRegular", sans-serif;
  text-align: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

/***********************************/
/* Not Found */

.NF-details-container {
  width: 100%;
  background-color: var(--dark-main);
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.NF-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.NF-details-title {
  font-family: "JakartaExtraBold", sans-serif;
  color: var(--dark-text);
  transition: all 0.5s;
  font-size: 30px;
  text-align: center;
  line-height: 36.7px;
  padding: 0.5rem 7vw;
}

.NF-details-content {
  display: flex;
  flex-wrap: wrap;
  margin-left: 4%;
  margin-bottom: 3%;
  margin-right: 4%;
  gap: 3%;
}

.NF-details-description {
  font-family: "JakartaRegular", sans-serif;
  font-size: 16px;
  color: var(--dark-text);
  line-height: 1.5;
  text-align: center;
  transition: all 0.5s;
}




/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {

  .nuka-carousel-next, .nuka-carousel-prev {
    width: 40px;
    height: 40px;
  }

  .nuka-carousel-next:hover, .nuka-carousel-prev:hover {
    transform: none;
  }

  .nuka-carousel-next:active, .nuka-carousel-prev:active {
    color: none;
  }

  .backbutton-container
  {
    margin-top: 3vh;
    width: 100%;
  }

  .backbutton
  {
    margin-top: 0vh;
    margin-left: 5%;
    height: 55px;
  }

  .noResults {
    font-size: 1em;
    margin-bottom: 10%;
  }
}

