.qutek-container-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    flex-shrink: 0;
    background-color: var(--dark-main);
    transition: all .5s;
    z-index: 2;
    overflow: hidden;
}

/****************************************************/
/*body qutek*/
.qutek-body {
    padding: 5%;
}

/****************************************************/
/* qutek*/

.qutek-technology {
    margin-top: 3%;
    background-color: var(--dark-secondary);
    border-radius: 1rem;
    color: var(--dark-text);
    overflow: hidden;
    transition: all .5s;
}

.qutek-listhadware {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 2rem;
    height: 100%;
    gap: 2rem;
}

.qutek-hardware-logo,
.qutek-hardware-arrow {
    width: 100%;
    height: 10vh;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    filter: var(--dark-img);
    transition: all .5s;
}

.qutek-hardware-arrow {
    background-image: url('./../../../../public/img/icones/arrow.png');
    height: 7vh;
}

.qutek-hardware-logo {
    margin: 2% 0%;
    background-image: url('./../../../../public/img/logo/qutek.png');
}

/****************************************************/
/* qutek systems*/

.qutek-systems {
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./../../../../public/img/assets/bgqutek.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
}

.qutek-systems-row {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 2rem 5%;
    height: 100%;
    gap: 2rem;
}

.qutek-systems-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 100px;
    height: 100%;
    min-height: 100px;
    gap: 0.5em;
}

.qutek-systems-item-title {
    font-family: "JakartaExtraBold";
    font-size: 2rem;
    color: white;
    text-align: center;
}

.qutek-systems-item-text {
    font-family: "JakartaRegular";
    font-size: 18px;
    line-height: 1.5rem;
    color: white;
    text-align: center;
}

/****************************************************/
/* qutek brief history*/

.qutek-brief-history {
    margin: 3% 0%;
    background-color: var(--dark-secondary);
    border-radius: 1rem;
    color: var(--dark-text);
    overflow: hidden;
    transition: all .5s;
    padding: 2rem;
}

.qutek-brief-history-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    margin-top: 1rem;
    gap: 0.8rem;
}

.qutek-brief-history-left {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 3rem;

}

.qutek-brief-history-right {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.qutek-brief-history-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: all .5s;
}

.qutek-brief-history-title {
    font-family: "JakartaExtraBold";
    font-size: 1.7rem;
    color: var(--dark-text);
    padding-top: 1rem;
    text-align: center;
    transition: all .5s;
}

.qutek-brief-history-subtitle {
    font-family: "JakartaMedium";
    font-style: italic;
    font-size: 1.3rem;
    color: var(--dark-text);
    text-align: center;
    margin-bottom: 1rem;
    transition: all .5s;
}


.qutek-brief-history-text {
    width: 100%;
    font-family: "JakartaRegular";
    font-size: 18px;
    line-height: 1.7rem;
    color: var(--dark-text);
    transition: all .5s;

}

.qutek-brief-history-timelinestart {
    width: 100%;
}


/****************************************************/
/* Timeline */

.vertical-timeline {
    padding: 0 !important;
}

.vertical-timeline::before {
    background: #B52A7D !important;
}

.vertical-timeline-element {
    position: relative;
    margin: 1em !important;
    padding: 0;
}

.vertical-timeline-element-title-important {
    width: 100%;
    font-family: "JakartaExtraBold";
    font-size: 1.1em;
    font-weight: bold;
    margin: 0;
}

.vertical-timeline-element-title {
    font-family: "JakartaExtraBold";
    font-size: 1.1em;
    font-weight: bold;
    margin: 0;
}

.vertical-timeline-element-texte-important {
    font-family: "JakartaRegular";
    font-size: 1em;
    line-height: 1.5;
    margin : 0 !important;
}

.vertical-timeline-element-texte {
    font-family: "JakartaRegular";
    font-size: 1em !important;
    line-height: 1.5;
    margin : 0 !important;
}

.div-sup-important {
    border-radius: 10px;
    background-color: #d88eba;
    margin-top: 5px !important;
    padding: 0.5em;
}


/****************************************************/
/* qutek gallery*/

.qutek-gallery {
    width: 100%;
}

/****************************************************/
/* qutek timeline*/

.qutek-timeline {
    margin-top: 3%;
    margin-bottom: 3%;
    background-color: var(--dark-secondary);
    border-radius: 1rem;
    color: var(--dark-text);
    overflow: hidden;
    transition: all .5s;
    padding: 1rem;

}

.qutek-timeline-title {
    font-family: "JakartaExtraBold";
    font-size: 1.7rem;
    color: var(--dark-text);
    text-align: left;
    padding: 1rem;
    transition: all .5s;

}

.qutek-timeline-content {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 2rem;
    height: 100%;
    gap: 2rem;
}

.qutek-timeline-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 100%;
    height: 100%;
    transition: all .5s;

    color: var(--dark-text);
    font-family: "JakartaMedium";
    font-size: 1.2rem;
    line-height: 2rem;
}


.qutek-timeline-date .important {
    font-family: "JakartaExtraBold";
    color: var(--dark-important);
}

.qutek-timeline-image {
    width: auto;
    height: 58vh;
    border-radius: 20px;
    background-image: url('./../../../../public/img/assets/timelinequtek.jpg');
    background-color: white;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all .5s;
}

/****************************************************/
/* qutek annexe*/

.qutek-annexe {
    margin-top: 3%;
    background-color: var(--dark-secondary);
    border-radius: 1rem;
    color: var(--dark-text);
    overflow: hidden;
    transition: all .5s;
    padding: 1rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: auto;
}

.qutek-annexe-document {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex: 1;
    margin-bottom: 1rem;
}

.qutek-annexe-document-title {
    font-family: "JakartaExtraBold";
    font-size: 1.5rem;
    color: var(--dark-text);
    text-align: left;
    padding: 1rem;
    transition: all .5s;
}

.qutek-annexe-document-download-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.qutek-annexe-document-download-list hr {
    border: 2px solid var(--dark-text);
    width: 100%;
    border-radius: 1rem;
    transition: all .5s;
    align-self: center;
}

.qutek-annexe-document-download-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: all .5s;

    color: var(--dark-text);
    font-family: "JakartaMedium";
    font-size: 1.1rem;
}

.qutek-annexe-document-download-item-title {
    padding: 1vh 1rem;
}

.qutek-annexe-document-download-item-button {
    color: var(--dark-download);
    transition: all .5s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vh 1rem;
    gap: 0.5rem;
    text-decoration: none;
}

.qutek-annexe-document-download-item-button:hover {
    color: var(--dark-text);
}

.qutek-annexe-document-download-item-button-icon {
    width: 1.4rem;
    height: 1.4rem;
    background-image: url('./../../../../public/img/icones/pdf.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all .5s;
    filter: var(--dark-img);

}


/****************************************************/
/*qutek contact*/

.qutek-annexe-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    transition: all .5s;
    flex: 1;
}

.qutek-annexe-contact-text {
    color: var(--dark-text);
    font-family: "JakartaExtraBold";
    font-size: 1.5rem;
    text-align: center;
    transition: all .5s;
}

.qutek-annexe-contact-button {
    position: relative;
    margin-top: 1%;
    background-color: var(--dark-extra3);
    color: var(--dark-text);
    transition: all .5s;
    width: auto;
    height: 3.9em;
    border: var(--dark-extra3) 0.2em solid;
    border-radius: 11px;
    text-align: right;
}

.qutek-annexe-contact-button:hover {
    background-color: var(--dark-extra2);
    transition: all .5s;
    cursor: pointer;
}

.qutek-annexe-contact-button svg {
    width: 2em;
    margin: -0.4em 0.8em 1em;
    position: absolute;
    display: flex;
    transition: all 0.4s ease;
    color: var(--dark-text);
    transition: all .5s;
}

.qutek-annexe-contact-button:hover svg {
    transform: translateX(5px);
}

.qutek-annexe-contact-button-text {
    margin: -4px;
    margin-left: 3em;
    margin-right: 1em;
    font-size: 1.2em;
    font-family: "JakartaRegular", sans-serif;
}

/****************************************************/
/* qutek line*/
.qutek-annexe-line {
    align-self: stretch;
    width: 0;
    border-left: 4px solid var(--dark-text);
    transition: all .5s;
    color: var(--dark-text);
    margin: 0 1rem;
    border-radius: 1rem;
}

/****************************************************************************************************/
/*Responsive Mobile*/

@media only screen and (min-width: 1170px) {
    .vertical-timeline.vertical-timeline--two-columns {
        width: 100% !important;
    }
}

@media (max-width: 768px) {

    .qutek-body {
        padding: 3%;
    }

    .qutek-technology {
        margin-top: 5vh;
    }

    .qutek-timeline {
        margin-top: 5vh;
        margin-bottom: 5vh;
    }
    
    .qutek-gallery {
        margin-top: 5vh;
    }

    .qutek-annexe {
        flex-direction: column;
        margin-top: 5vh;
        margin-bottom: 3vh;
    }

    /*******************/

    .qutek-listhadware {
        flex-direction: column;
        gap: 1rem;
        margin: 0;
        margin-bottom: 2vh;
        border-radius: 0pc;
    }

    .qutek-hardware-arrow {
        height: 10vh;
    }

    .qutek-systems {
        height: auto;
        gap: 2rem;
    }

    .qutek-systems .qutek-systems-row:first-child {
        margin-top: 20px;
    }

    .qutek-systems .qutek-systems-row:last-child {
        margin-bottom: 20px;
    }

    .qutek-systems-row {
        flex-direction: column;
        margin: 0 5vw;
    }

    .qutek-systems-item-title {
        font-size: 1.5rem;
    }

    .qutek-systems-item-text {
        font-size: 14px;
    }

    /*******************/
    /* qutek brief history */   

    .qutek-brief-history {
        padding: 1rem;
        margin: 5vh 0;
    }

    .qutek-brief-history-container {
        flex-direction: column;
    }

    .qutek-brief-history-left,
    .qutek-brief-history-right {
        width: 100%;
    }

    .qutek-brief-history-left {
        gap : 2rem;
    }


    .qutek-brief-history-title {
        font-size: 1.5rem;
    }

    .qutek-brief-history-subtitle {
        font-size: 1.1rem;
    }

    .qutek-brief-history-text {
        font-size: 16px;
        line-height: 1.5rem;
    }


    /*******************/
    /* qutek timeline */

    .vertical-timeline {
        padding: initial !important;
    }
    
    .vertical-timeline-element {
        margin-left: 0 !important;
        padding: initial !important;
    }

    .vertical-timeline-element-date {
        display: none !important;
    }

    .qutek-timeline-content {
        flex-direction: column;
        margin: 0;
        gap: 0;
    }

    .qutek-timeline-date {
        font-size: 1rem;
    }

    .qutek-timeline-date ul {
        padding-left: 15px;
    }

    .qutek-timeline-title {
        font-size: 1.5rem;
        text-align: center;
        padding: 0;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .qutek-timeline-image {
        height: 20vh;
        border-radius: 10px;
    }

    /*******************/
    /* qutek annexe */

    .qutek-annexe-contact {
        width: 100%;
        margin-bottom: 2vh;
    }

    .qutek-annexe-line {
        border-left: none;
        border-top: 1px solid var(--dark-text);
        height: 0;
        width: 200px;
        margin: 0.5rem auto;
    }

    .qutek-annexe-document-download-item-button {
        padding: 1vh 0.5rem;
    }

    .qutek-annexe-document-download-item-button-icon {
        width: 1.2rem;
        height: 1.2rem;
    }

    .qutek-annexe-document-download-item-title {
        padding: 1vh 0.5rem;
    }

    .qutek-annexe-document-download-item {
        align-items: center;
    }

    .qutek-annexe-contact-button {
        width: 100%;
    }

    .qutek-annexe-contact-button svg {
        margin: -0.4em 0.8em 1em;
    }

    .qutek-annexe-contact-text {
        font-size: 1.2rem;
        padding: 0;
    }

    .qutek-annexe-document-title {
        font-size: 1.5rem;
        text-align: center;
    }

    .qutek-annexe-document-download-item {
        font-size: 1rem;
    }

    .qutek-annexe-document-download-item-button-icon {
        width: 1rem;
        height: 1rem;
    }

    .qutek-annexe-document-download-item-title {
        font-size: 1rem;
    }

}