.footer {
  position: relative;
  width: 100%;
  height: auto;
  background-color: var(--dark-secondary);
  transition: all .5s;
  text-align: center;

}

/********************************************/

.grid-container-footer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 0.5fr);
  justify-content: center;
  text-align: center;
  margin: 2% 16%;
}

.mobile-container-footer {
  display: none;
}

/********************************************/

.title-footer {
  font-size: 0.8rem;
  color: var(--dark-text);
  transition: all .5s;
  font-family: "JakartaBold";
  align-items: center;

}

.text-footer {
  font-size: 0.8rem;
  color: var(--dark-text);
  transition: all .5s;
  font-family: "JakartaRegular";
  list-style-type: none;
  padding-left: 0;
}

.text-footer li {
  margin: 0.5rem 0;
}

.text-footer a {
  color: var(--dark-text);
  transition: all .5s;
  text-decoration: none;
}

.text-footer a:hover {
  text-decoration: underline;
}

/********************************************/

.footer-bottom {
  padding-bottom: 10px;
  color: var(--dark-text);
  transition: all .5s;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.container {
  max-width: 90%;
  height: auto;
  margin: 0 auto;
}

.lignes-container {
  position: relative;
  width: 100%;
  height: auto;
}

.line-footer {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--dark-text2);
  transition: all .5s;
}


.logo-container {
  display: block;
  width: auto;
  height: auto;
  filter: var(--dark-img);
  transition: all .5s;
}

.logo-container-img {
  position: relative;
  height: 54px;
  margin: 10px;
  background-image: url(../../../../public/img/logo/logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;

}

.liens-container {
  position: absolute;
  top: 0;
  right: 5%;
  width: 71%;
  height: 80px;
  list-style-type: none;
  text-align: right;
  font-family: "JakartaBold";

}

.liens-container li {
  display: inline-block;
  vertical-align: middle;
  line-height: 80px;
  color: var(--dark-text3);
  transition: all .5s;
  font-family: "JakartaExtraBold";
  font-size: 13px;
  letter-spacing: 1px;
}


.liens-container li:hover {
  text-decoration: underline;
}

.liens-container .a {
  color: var(--dark-text);
  transition: all .5s;
  text-decoration: none;
}

.liens-container img {
  vertical-align: middle;
}

.barre {
  width: 1px;
  height: 30px;
  background-color: var(--dark-text3);
  transition: all .5s;
  margin: 0 15px;
}

.copyright {
  margin-top: 2vh;
  text-align: left;
  color: var(--dark-text3);
  transition: all .5s;
  font-family: "JakartaMedium";
  font-size: 13px;
  line-height: 25px;
  letter-spacing: 2px;
}

.liens-container2 {
  position: absolute;
  top: 50%;
  right: 5%;
  width: 60%;
  height: 80px;
  list-style-type: none;
  text-align: right;
  font-family: "JakartaBold";
}

.liens-container2 li {
  display: inline-block;
  vertical-align: middle;
  line-height: 80px;
  color: var(--dark-text3);
  transition: all .5s;
  font-family: "JakartaRegular";
  font-size: 13px;
  letter-spacing: 1px;
}

.liens-container2 li:hover {
  text-decoration: underline;
}

.liens-container2 .a {
  color: var(--dark-text);
  transition: all .5s;
  text-decoration: none;
}

.footer-mobile-container {
  display: flex;
  justify-content: space-between;
  align-items: center;  
}


/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {


  /****/

  .container-footer {
    display: none;
  }

  .mobile-container-footer {
    grid-template-columns: 1fr;
    display: block;
    /* Passer à un affichage en bloc */
  }

  /****/

  .text-footer {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 3vh;
  }

  .text-footer li {
    margin: 1rem 0;
  }

  .footer-bottom {
    text-align: center;
  }

  .title-head {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-footer {
    cursor: pointer;
    padding-top: 0px;
  }

  .arrow {
    display: inline-block;
    margin-left: 10px;
    /* Ajouter un peu d'espace entre le titre et la flèche */
    color: var(--dark-text);
    /* Changer la couleur de la flèche */
  }

  .footer-section {
    border-top: 1px solid var(--dark-text);
  }

  .footer-section-first {
    margin-top: 1vh;
  }

  .footer-section-last {
    border-top: 1px solid var(--dark-text);
    margin-bottom: 3vh;
  }

  .logo-container-img {
    background-position: center;
    width: 200px;
    height: 40px;
    margin-left: 0;
  }

  .copyright {
    margin-top: 0vh;
    text-align: center;
  }

}