.product-details-container {
  width: 100%;
  /*background-color: #f5f5f5; à voir si on laisse en blanc*/
  background-color: var(--dark-main);
  transition: all 0.5s;
}

.product-details-top {
  display: flex;
  flex-direction: column;
  margin: 2% 4%;
}

.product-details-top p {
  font-size: 18px;
  color: var(--dark-text);
  transition: all 0.5s;
  font-family: "JakartaRegular", sans-serif;
}

.product-details-title {
  font-size: 32px;
  color: var(--dark-text);
  transition: all 0.5s;
  margin-bottom: 1vh;
  margin-top: 0;
  font-family: "JakartaExtraBold", sans-serif;
}

.product-details-content {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5%;
  margin-left: 4%;
  margin-right: 4%;
  gap: 3%;
}

.product-details-image {
  flex: 1;
  width: 0;
  height: 100%;
  border-radius: 10px;
  background-color: var(--dark-secondary);
  transition: all 0.5s;
  padding: 1%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

}

.product-details-image img {
  object-fit: cover; /* avoir toutes les images dans le même format */
  aspect-ratio: 1 / 1; /* Force le ratio 1:1 */

  width: 100%;
  height: 100%;
  margin-top: 10%;
}

.product-image-container {
  margin: 0 auto;
  width: 80%;
  height: 85%;
}

.product-image {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: all 0.2s;
  cursor: pointer;
}

.product-image:hover {
  transition: all 0.2s;
  scale: 1.01;
  box-shadow: 0 0 30px var(--dark-text);
}

.product-details-description {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: "JakartaMedium", sans-serif;
  box-sizing: border-box;
}

.product-details-description-wrapper {
  height: 100%;
}

.product-details-description-title {
  font-size: 24px;
  color: #333;
  font-family: "JakartaExtraBold", sans-serif;
}

.product-details-description-text {
  font-size: 18px;
  color: #666;
  line-height: 1.5;
}


/****************************/

.button-container-machdet {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3%;
  align-items: flex-end;
  margin-top: 3%;
}

.machdet-contact-button {
  position: relative;
  margin-top: 5%;
  background-color: var(--dark-extra3);
  color: var(--dark-text);
  transition: all .5s;
  width: auto;
  height: 3.9em;
  border: var(--dark-extra3) 0.2em solid;
  border-radius: 11px;
  text-align: right;
}

.machdet-contact-button:hover {
  background-color: var(--dark-extra2);
  transition: all .5s;
  cursor: pointer;
}

.machdet-contact-button svg {
  width: 2em;
  margin: -0.4em 0.8em 1em;
  position: absolute;
  display: flex;
  transition: all 0.4s ease;
  color: var(--dark-text);
  transition: all .5s;
}

.machdet-contact-button:hover svg {
  transform: translateX(5px);
}

.machdet-contact-button-text {
  margin: -4px;
  margin-left: 3em;
  margin-right: 1em;
  font-size: 1.2em;
  font-family: "JakartaRegular", sans-serif;
}

/******************************/
/*Download document*/

.machdet-document-download-item-button{
  color: var(--dark-download);
  transition: all .5s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vh 1rem;
  gap: 0.5rem;
  text-decoration: none;
}

.machdet-document-download-item-button:hover{
  background-color: var(--dark-secondary);
  transition: all .5s;
  cursor: pointer;
  border-radius: 10px;
  color: var(--dark-text);
}

.machdet-document-download-item-button-icon {
  width: 1.4rem;
  height: 1.4rem;
  background-image: url('./../../../../public/img/icones/pdf.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all .5s;
  filter: var(--dark-img);
}

/******************************/
/*zoom button*/
.zoom-button{
  position: relative;
  font-family: "JakartaRegular", sans-serif;
  background-color: var(--dark-main);
  color: var(--dark-text);
  transition: all .5s;
  width: 7em;
  height: 3em;
  border: #3654ff 0.2em solid;
  border-radius: 10px;
}

.zoom-button:hover{
  background-color: var(--dark-secondary);
  transition: all .5s;
  cursor: pointer;
}

.button-container2 {
  display: flex;
  margin: 1%;
  flex-direction: column;
  justify-content: space-between;
  gap: 3%;
  align-items: center;
}


/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {

  .hide-dots-on-mobile {
    display: none;
  }

  .product-details-top {
    margin: 8% 4%;
    text-align: center;
  }

  .product-details-top p {
    font-size: 18px;
  }

  .product-details-content {
    flex-direction: column;
    margin-bottom: 2%;
    margin-left: 2%;
    margin-right: 2%;
    gap: 2%;
  }

  .product-details-image {
    width: 95%;
    box-sizing: border-box;
    margin: 0 auto;
  }

  .product-image-container {
    width: 90%;
  }

  .product-details-description {
    width: 95%;
    box-sizing: border-box;
    margin: 5vh auto;
  }

  .product-details-description-title {
    font-size: 18px;
  }

  .product-details-description-text {
    font-size: 14px;
  }

  .contactus {
    width: 12em;
    height: 7vh;
    margin: 1vh auto;
  }


  .table-container {
    width: 90%;
  }

  .table-wrapper {
    margin: 2% 2%;
    width: 100%;
  }

  .zoom-button {
    width: 8em;
    height: 7vh;
    margin: 1vh auto;
  }

  .button-container2 {
    margin: 1%;
  }


}