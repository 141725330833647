.gallery-page {
    background-color: var(--dark-main);
    transition: all 0.5s;
    width: 100%;
    height: auto;
}

.gallery-container {
    background-color: var(--dark-secondary);
    transition: all 0.5s;
    padding: 1%;
    margin: 5% 0;
}

.gallery-container img {
    border-radius: 10px;
    transition: all 0.5s;
}

.gallery-container img:hover {
    cursor: pointer;
    scale: 1.03;
    transition: all 0.5s;
    filter: brightness(1.1);
}

.gallery-title {
    font-family: "JakartaExtraBold", sans-serif;
    color: var(--dark-text);
    transition: all 0.5s;
    font-size: 30px;
    text-align: center;
    letter-spacing: 2px;
    line-height: 36.7px;
    margin: 7vh 0 0vh;
    width: 100%;
}

.gallery-search {
    margin-bottom: 5vh;
}

/***********************************/
/*pagination*/

.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2vh;
}

.pagination {
    display: flex;
    list-style: none;
    padding: 0;
 }
 
 .pagination li {
    margin: 0 5px;
 }
 
 .pagination button {
    font-family: "JakartaExtraBold", "sans-serif";
    background-color: var(--dark-secondary);
    color: var(--dark-text);
    border: 1px solid var(--dark-text);
    transition: all 0.5s;
    border-radius: 5px;
    padding: 8px 16px;
    text-decoration: none;
    cursor: pointer;
 }

.pagination button:hover:not(.active) {
    background-color: var(--dark-text);
    color: var(--dark-secondary);
    scale: 1.1;
    transition: all 0.2s;
}
 
 .pagination button.active {
    background-color: var(--dark-text);
    color: var(--dark-secondary);
 }
 
/***********************************/
#loading-progress {
  position: fixed;
  bottom: 30px;
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: var(--dark-text);
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  z-index: 9001;
  font-family: "JakartaExtraBold";
}


 /****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {
  
    .gallery-container {
        padding: 5%;
        box-sizing: border-box;
    }

    .infinite-scroll-component::-webkit-scrollbar {
        display: none;
    }
}

