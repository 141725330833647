.products-container {
  position: relative;
  width: 100%;
  height: auto;
  background-color: var(--dark-main);
  transition: all 0.5s;
  text-align: center;
}

/******************************/

.title-productCat {
  font-family: "JakartaExtraBold", sans-serif;
  color: var(--dark-text);
  transition: all 0.5s;
  font-size: 30px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 36.7px;
  text-transform: uppercase;
  width: 100%;
  margin-top: 5vh;

}

.subtitle-productCat {
  font-family: "JakartaRegular", sans-serif;
  color: var(--dark-text);
  transition: all 0.5s;
  font-size: 20px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 24.5px;
  margin: 0 0 5vh;
  width: 100%;
}


.text-productCat {
  font-family: "JakartaRegular", sans-serif;
  color: var(--dark-text);
  transition: all 0.5s;
  font-size: 18px;
  text-align: left;
  line-height: 26.1px;
  margin: 0 0 1vh;
  width: 100%;
}

.text-productCat.centertxt {
  text-align: center;
}

.header-productCat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5vh auto;
  max-width: 78vw;
  height: auto;
  background-color: var(--dark-secondary);
  transition: all 0.5s;
  border-radius: 16px;
  padding: 2vh 5vh;
  box-sizing: border-box;

}

.accordion-productCat {
  box-shadow: none !important;
  border-bottom: none;
}

.accordion-productCat .Mui-expanded {
  background-color: var(--dark-secondary) !important;
  color: var(--dark-text);
  border-radius: 16px !important;

}

.lists-container-productCat {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: left;
  margin: 5vh auto;
  font-family: "JakartaRegular", sans-serif;
  line-height: 26.1px;
}

/******************************/
/*input bar*/
.search-bar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-top: 4%;
}

.search-bar-item {
  width: 30%;
  padding: 0.7rem;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  background-color: var(--dark-secondary);
  color: var(--dark-text);
  transition: border-color 0.2s, box-shadow 0.2s;
  font-family: 'JakartaSemiBold', sans-serif;
  font-size: 1rem;
  transition: all 0.5s;
}

.search-bar-item:focus {
  outline: none;
  /* border: var(--dark-navbar) 2px solid; */
  /* box-shadow: 0px 0px 0 3px grey; */
}

.search-icon {
  position: absolute;
  right: 35%;
  cursor: pointer;
  color: var(--dark-text);
}

.space-search {
  margin: 0;
  margin-top: 5%;
}

/******************************/
/*list articles container*/

.articles {
  display: grid;
  max-width: 78vw;
  margin-inline: auto;
  padding-inline: 24px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
  margin-bottom: 5%;
  padding-top: 5%;
}

.articles.cat {
  max-width: 1200px;
}

.article-wrapper {


  display: flex;
  flex-direction: column;
  height: 100%;
}

/******************************/
/*article */
.articles article {
  --img-scale: 1.001;
  --title-color: var(--dark-text);
  --link-icon-translate: -20px;
  --link-icon-opacity: 0;
  position: relative;
  border-radius: 16px;
  box-shadow: none;
  background: var(--dark-secondary);
  transform-origin: center;
  transition: all 0.4s ease-in-out;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.articles article a::after {
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
  cursor: pointer;
  content: "";
}

/* basic article elements styling */
.articles article h2 {
  margin: 0 0 18px 0;
  font-family: "JakartaExtraBold", sans-serif;
  font-size: 1.8rem;
  letter-spacing: 0.06em;
  color: var(--title-color);
  transition: color 0.3s ease-out;
}

.articles figure {
  margin: 0;
  padding: 0;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

.articles article img {
  max-width: 100%;
  transform-origin: center;
  transform: scale(var(--img-scale));
  transition: transform 0.4s ease-in-out;
}

.article-body {
  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
}

.article-body h2 {
  text-align: center;
}

.article-body hr {
  width: 100%;
  border-top: 1px solid #e0e0e0;
  border-radius: 10px;
  margin: 0;
}

.article-body p {
  margin-top: 20px;
  color: var(--dark-text);
  transition: all 0.5s;
  text-align: center;
  height: 100%;
  width: 100%;
  word-wrap: break-word;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;

  font-family: "JakartaRegular", sans-serif;
  font-size: 16px;
}

.articles article a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: white;
  background-color: var(--dark-barnav);
  font-weight: 600;
  transition: all 0.5s;
}


.articles article a:focus {
  outline: 1px dotted #28666e;
}

.articles article a .icon {
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  transform: translateX(var(--link-icon-translate));
  opacity: var(--link-icon-opacity);
  transition: all 0.3s;
}

.articles article:has(:hover, :focus) {
  --img-scale: 1.1;
  --title-color: #ff4cb7;
  --link-icon-translate: 0;
  --link-icon-opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  color: var(--dark-text);
  width: 1px;
}

.read-more {
  display: inline-block;
  padding: 8px 16px;
  margin-top: 16px;
  border-radius: 11px;
  /* border: #3654ff 0.1em solid; */
  background: transparent;
  text-decoration: none;
  font-family: "JakartaExtraLight", sans-serif;
  font-size: 1em;
}


/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {
  .products-container {
    text-align: left;
  }

  .search-bar-container {
    width: 100%;
    margin: 5% 0%;
  }

  .search-bar-item {
    width: 80%;
    font-size: 18px;
  }

  .search-icon {
    right: 10%;
  }

  .title-productCat {
    font-size: 25px;
    margin-top: 7vh;
    line-height: 35px;
  }

  .subtitle-productCat {
    font-size: 16px;
  }

  .text-productCat {
    font-size: 16px;
    line-height: 23px;
  }

  .header-productCat {
    padding: 0;
    max-width: 90%;
    /* border-radius: 0; */
  }

  .lists-container-productCat {
    flex-direction: column;
    align-items: center;
  }

  .article-body {
    padding: 16px;
  }
  
  .article-body p {
    margin-top: 1em;
  }

  .read-more {
    margin-top: 0;
  }
}

@media screen and (max-width: 960px) {

  .articles {
    padding-inline: 20px;
    max-width: 100%;
  }

  .articles article {
    container: card/inline-size;
  }

}

@container card (min-width: 380px) {
  .article-wrapper {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 16px;
  }

  .article-body {
    padding-left: 0;
  }

  .articles figure {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .articles figure img {
    height: 100%;
    aspect-ratio: 1;
    object-fit: cover;
  }
}