.sitemap-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    height: 100%;
    background-color: var(--dark-main);
    transition: all 0.5s;

}

.title-sm {
    margin-top: 4%;
    margin-left: 65px;
    width: auto;
    height: 37px;
    font-family: "JakartaExtraBold", sans-serif;
    color: var(--dark-text);
    transition: all .5s;
    font-size: 20px;
    text-align: left;
    letter-spacing: 2px;
    line-height: 36.7px;
    text-transform: uppercase;
}

/******************************/

.tree {
    min-height: 20px;
    padding: 20px;
    margin-bottom: 5%;
    user-select: none;
}

.tree li {
    list-style-type: none;
    margin: 0;
    padding: 10px 5px 0 5px;
    position: relative;
    transition: all 0.3s ease;

}

.tree li::before,
.tree li::after {
    content: '';
    left: -20px;
    position: absolute;
    right: auto;
}

.tree li::before {
    border-left: 1px solid #999;
    bottom: 50px;
    height: 100%;
    top: 0;
    width: 1px;
}

.tree li::after {
    border-top: 1px solid #999;
    height: 20px;
    top: 30px;
    width: 25px;
}

.tree li span {
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border: 1px solid #999;
    border-radius: 5px;
    display: inline-block;
    padding: 3px 8px;
    text-decoration: none;
    background-color: var(--dark-sitemap);
    color: #000;
    transition: all 0.5s;
    font-family: "JakartaRegular";
    font-size: 1.2em;
    padding: 10px 20px;

}

.tree li.parent_li>span {
    cursor: pointer;
}

.tree>ul>li::before,
.tree>ul>li::after {
    border: 0;
}

.tree li:last-child::before {
    height: 30px;
}

.tree li.parent_li>span:hover,
.tree li.parent_li>span:hover+ul li span {
    background: #c4c4c4;
    border: 1px solid #94a0b4;
}

/*******************************************/

.sitemap {
    margin-right: 10px;

}

.itemSM:hover {
    scale: 1.05;
    transition: all 0.3s;
    background: #cacaca;
    border: 1px solid #94a0b4;
    cursor: pointer;
}

.itemSM:active {
    scale: 0.95;
    transition: all 0.1s;
}

.linkSM .itemSM{
    color: #5050e5;
    
}