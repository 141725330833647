.contact-container {
  background-color: var(--dark-main);
  transition: all 0.5s;
  width: 100%;
  height: auto;
}

/******************************/

.title-contact {
  font-family: "JakartaExtraBold", sans-serif;
  font-weight: 800;
  color: var(--dark-text);
  transition: all 0.5s;
  font-size: 30px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 36.7px;
  margin: 7vh 0 5vh;
}

/******************************/

.contact-body {
  padding: 0 5%;
}

/******************************/
/* form */

.contact-form {
  background-color: var(--dark-secondary);
  border-radius: 1rem;
  color: var(--dark-text);
  overflow: hidden;
  transition: all .5s;
  padding: 1rem;
}

.contact-form-title {
  font-family: "JakartaExtraBold";
  font-size: 1.7rem;
  color: var(--dark-text);
  text-align: left;
  padding: 1rem;
  transition: all .5s;
}

.contact-form-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.form-label {
  white-space: nowrap;
  font-family: "JakartaRegular", sans-serif;
  font-size: 1rem;
  color: var(--dark-text);
  transition: all 0.5s;
  align-self: center;
  margin-bottom: 5px;
}

.form-group {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: white;
  color: black;
  transition: all 0.5s;
  font-family: "JakartaRegular", sans-serif;
  font-size: 1rem;
  box-sizing: border-box;
  resize: none;
}


.form-group-select {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.form-group select,
.form-group-select select {
  width: auto;
}

.full-select {
  width: auto !important;
}

.double-input {
  display: flex;
  flex-direction: row !important;
  gap: 10px;
}

.double-input-extend {
  flex-grow: 1;
}

.form-group-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-group-row .form-group {
  flex: 1;
  margin-right: 50px;
}

.form-group-row .form-group:last-child {
  margin-right: 0;
}

.form-extra {
  display: flex;
  justify-content: end;
  gap: 50px;
  margin-top: 2vh;
  margin-bottom: 4vh;
  align-items: center;
}

.form-label {
  width: 100%;
  display: block;
  font-size: 1rem;
  font-family: "JakartaRegular", sans-serif;
  color: var(--dark-text);
  transition: all 0.5s;
  text-align: left;
}


/******************************/
/* send button */
.contact-button {
  height: 50px;
  font-family: inherit;
  font-size: 20px;
  background: royalblue;
  color: white;
  padding: 0em 2em;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
  font-family: "JakartaBold", sans-serif;
}

.contact-button span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

.contact-button svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

.contact-button:hover .svg-wrapper {
  animation: fly-1 0.3s ease-in-out infinite alternate;
}

.contact-button:hover svg {
  transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

.contact-button:hover span {
  transform: translateX(5em);
}

.contact-button:active {
  transform: scale(0.95);
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}

/******************************/
/* contact info plassys */

.contact-info-plassys {
  background-color: var(--dark-secondary);
  border-radius: 1rem;
  color: var(--dark-text);
  overflow: hidden;
  transition: all .5s;
  padding: 1rem;
  margin-top: 5vh;
  margin-bottom: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contact-info-title-plassys {
  font-family: "JakartaExtraBold";
  font-size: 1.7rem;
  color: var(--dark-text);
  text-align: left;
  padding: 1rem;
  transition: all .5s;
}

.contact-info-content-plassys {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  gap: 1.5vw;

}

.contact-info-coord-plassys {
  display: flex;
  flex-direction: column;
  align-items: start;
  flex: 0.3;
}

.contact-info-map-plassys {
  flex: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-info-map-plassys .GMap {
  width: 100%;
  height: 500px;
  margin: 0 auto;
  border: 0;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-info-image-plassys {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-image: url('./../../../../public/img/assets/bureaux2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.custom-datalist {
  max-height: 200px;
  /* Définir une hauteur maximale */
  overflow-y: auto;
  /* Ajouter un défilement vertical si nécessaire */
}

/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {

  .contact-form-title {
    font-size: 1.5rem;
    text-align: center;
  }

  .form-group {
    flex-direction: column;
    align-items: center;
  }

  .form-group-row {
    flex-direction: column;
    border-top: 2px solid var(--dark-text);
    padding: 20px 0;
  }

  .form-extra {
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }

  .form-group-select {
    flex-direction: column;
    gap: 15px;
  }

  .form-group-select div {
    width: 100%;
  }

  .form-group select,
  .form-group-select div select {
    width: 100%;
  }

  .full-select {
    width: 100% !important;
  }

  .double-input {
    flex-direction: column !important;
    margin-right: 0 !important;
  }

  .double-input-extend {
    flex-grow: 1;
  }

  .contact-info-title-plassys {
    font-size: 1.5rem;
    text-align: center;
  }

  .contact-info-content-plassys {
    flex-direction: column;
    gap: 5vw;
  }

  .contact-info-map-plassys {
    flex: 1;
  }

  .contact-info-map-plassys .GMap {
    width: 100%;
    height: 300px;
    margin: 0 auto;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .contact-info-image-plassys {
    margin-top: 10px;
    height: 100px;
  }
}