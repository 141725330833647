/*for recruitment & PrivacyPolicy */
.recrut-container {
    background-color: var(--dark-main);
    transition: all 0.5s;
    width: 100%;
    height: auto;
    text-align: center;
}


.recrut-title {
    font-family: "JakartaExtraBold", sans-serif;
    color: var(--dark-text);
    transition: all 0.5s;
    font-size: 30px;
    text-align: center;
    letter-spacing: 2px;
    line-height: 36.7px;
    margin: 7vh 0 5vh;
    width: 100%;
}

.recrut-subtitle {
    font-size: 30px;
    color: #333;
    font-family: "JakartaExtraBold", sans-serif;
}

.recrut-content {
    width: 80%;
    height: auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    margin-bottom: 5%;
    text-align: left;
}

.recrut-text {
    font-family: "JakartaRegular", sans-serif;
    color: #333;
} 

#pp {
    display: block;
}