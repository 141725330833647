/***************************************/
.distrib-container {
  background-color: var(--dark-main);
  transition: all 0.5s;
  width: 100%;
  height: auto;
}

.contact-container-distrib {
  box-sizing: border-box;
  width: 90%;
  height: fit-content;
  margin: 0 auto;
  margin-bottom: 5%;
  transition: all 0.5s;
  padding: 35px;
  border-radius: 10px;
}

.distrib-title {
  font-family: "JakartaExtraBold", sans-serif;
  color: var(--dark-text);
  transition: all 0.5s;
  font-size: 30px;
  margin: 2vh 0 5vh;
}

.contact-distrib-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

/****************************************************************************************************/
/*Responsive Mobile*/

@media screen and (max-width: 1000px) {
  .contact-distrib-list {
    grid-template-columns: repeat(2, 1fr);
  }
  
  
}

@media (max-width: 768px) {
  .contact-distrib-list {
    grid-template-columns: 1fr;
    gap : 10px;
  }

  .distrib-title {
    text-align: center;
    margin: 0 0 5vh 0;
  }
  
}