.space404 {
    margin: 0;
    padding: 0;
    background-color: var(--dark-main);
    transition: all .5s;
    height: auto;
    width: 100%;
    font-family: "JakartaBold";
    color: #fff;
    text-align: center;
  }

  .Color404 {
    fill: var(--dark-text);
    transition: all .5s;
  }
  .not-found {
    width: 560px;
    height: 225px;
    margin-right: -10px;
    margin-top: 30%;
  }
  .starry-sky {
    display: block;
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .notfound-copy {
    color: var(--dark-text);
    transition: all .5s;
    position: absolute;
    top: 25px;
    right: 10%;
    text-align: right;
  }
  .notfound404 {
     font-weight: 700;
    font-size: 40px;
  }

  .all-stars {
    animation: blinkblink 7s linear infinite;
    background-color: var(--dark-text2);
    transition: all .5s;
  }
  
  @keyframes blinkblink {  
    50% { opacity: 0.1; }
  }

/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {
 /*descendre le starry sky */
 .starry-sky {
    display: block;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    margin-top: 40%;
  }

  .space404 {
    height: 800px;
  }

  .notfound404 {
    font-size: 30px;
  }

}

