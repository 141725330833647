/* Navbar.js */

.navbar {
  /* background-color: var(--dark-main); */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
  transition: all .5s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 70px;
  display: flex;
  align-items: center;
  /* border-bottom: 5px solid #B52A7D; */
  /* overflow: hidden; */
  z-index: 10;
  font-family: "JakartaRegular", sans-serif;
  flex-direction: row;
  /* overflow: hidden; */
}

html[data-theme="dark"] .navbar.top .dropdown-menu {
  background-color: var(--dark-main);
  border: 2px solid var(--dark-main);
}

html[data-theme="light"] .navbar.top a,
html[data-theme="light"] .navbar.top span {
  color: white;
  transition: all .3s;
}

html[data-theme="light"] .navbar.top .dropdown-arrow {
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  transition: all .3s;
}

html[data-theme="light"] .navbar.top .logo {
  filter: none;
}

html[data-theme="light"] .navbar.top .dropdown-menu {
  background-color: #001F3F;
  border: 2px solid #001F3F;
}

html[data-theme="light"] .navbar.top .dropdown-menu li:hover {
  background-color: #ff3cb1;
}

html[data-theme="light"] .navbar.top .dropdown-menu li {
  color: black;
}

html[data-theme="light"] .navbar.top img {
  filter: invert(0);
}



.navbar.scrolled {
  background-color: var(--dark-main);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-bottom: 2px solid var(--dark-barnav);
}

.nav-item {
  flex-grow: 1;
  text-align: center;
  text-decoration: none;
  color: var(--dark-text);
  transition: all .5s;
  position: relative;
  cursor: pointer;
}



/*********************************************/
/*logo navbar*/
.logo {
  display: flex;
  width: 100%;
  max-height: 100%;
  position: relative;
  filter: var(--dark-img);
  transition: all .5s;
}

.LogoImg {
  height: 100%;
  width: 221px;
  background-image: url(../../../../public/img/logo/logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  transition: all .5s;
}

/*Animation logo*/
.LogoImg:hover {
  transform: scale(1.05);
  transition: all .5s;
  transition-delay: var(--_s);
}

.hover-3 {
  height: 50px;
  width: 221px;
  margin: 0px 40px;
  padding: 10px 5px;
  --b: 4px;
  --c: var(--dark-barnav);
  color: #0000;
  /* padding-block: var(--b); */
  background: linear-gradient(var(--c) 50%, #000 0) 0% calc(100% - var(--_p, 0%))/100% 200%, linear-gradient(var(--c) 0 0) 0% var(--_p, 0%)/var(--_p, 0%) var(--b) no-repeat;
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
  transition: .15s var(--_s, 0s) linear, background-size .3s calc(.3s - var(--_s, 0s));
}

.hover-3:hover {
  --_p: 100%;
  --_s: .3s;
}


/*********************************************/


/*liens navbar*/
.links {
  display: flex;
  list-style: none;
  /* padding: 0; */
  gap: 40px;
  height: 100%;
  justify-content: flex-end;

  /* Style 1 */
  margin-left: auto;
  /*Style 2*/
  /*Decommenter pour centrer les liens*/
  /*margin: auto;*/


}

.links a {
  display: block;
  text-decoration: none;
  color: var(--dark-text);
  transition: all .3s;
  position: relative;
}

.links a.active::before {
  position: absolute;
  left: 0;
  width: 100%;
  /* height: 9px; */
  background-color: #B52A7D;
  flex-shrink: 0;
  cursor: auto;
}

.links a:hover::before {
  left: 0;
}

/*********************************************/
/*barre active*/
/* .active-bar {
  visibility: hidden;
  position: absolute;
  bottom: -5px;
  height: 9px;
  background-color: var(--dark-barnav);
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transform-origin: left;
  border-radius: 15px;
  min-width: 50px;
} */

.navbar-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  max-width: 100%;
  max-height: 100%;
}

.full {
  display: block;
  padding: 25px 0;
  color: #18272F;
  position: relative;
  text-decoration: none;
}

.full::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--dark-barnav);
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
}

.full:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}



/****************************************************/
/*Switch L/D*/
/* .switch-name {
  display: block;
  position: relative;
  border-radius: 5em;
  cursor: pointer;
  width: 80px;
  height: 40px;
  overflow: hidden;
  transition: all 0.5s;
  margin-right: 30px;

} */

.switch-name {
  display: block;
  position: relative;
  border-radius: 5em;
  cursor: pointer;
  width: 60px;
  height: 30px;
  overflow: hidden;
  transition: all 0.5s;
  margin-right: 30px;

}

.switch-name .back {
  background-color: var(--dark-extra);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: .5s ease-in-out;

}

.switch-name .checkbox {
  opacity: 0;
}

.switch-name .checkbox:checked~.moon {
  transform: translate(100%) rotate(180deg);
  opacity: 0;
}

.switch-name .checkbox:checked~.sun {
  transform: translate(100%) rotate(180deg);
  opacity: 1;
}

.switch-name .moon {
  position: absolute;
  left: 5px;
  top: -2px;
  height: 70%;
  padding: .4em;
  fill: var(--dark-point);
  transition: all .5s;
}

.switch-name .sun {
  position: absolute;
  left: -8px;
  top: -2px;
  height: 70%;
  padding: .4em;
  fill: var(--dark-point);
  opacity: 0;
  transition: all .5s;
}


/****************************************************************************************************/
/*Responsive Mobile*/

/*hamburger*/
.hamburger {
  cursor: pointer;
  display: none;
  margin-right: 7%;
}

.hamburger input {
  display: none;
}

.hamburger svg {
  height: 3em;
  transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
  fill: none;
  stroke: var(--dark-hamburger);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3;
  transition: stroke-dasharray 400ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-top-bottom {
  stroke-dasharray: 12 63;
}

.hamburger input:checked+svg {
  transform: rotate(-45deg);
}

.hamburger input:checked+svg .line-top-bottom {
  stroke-dasharray: 20 300;
  stroke-dashoffset: -32.42;
}

/************************/
/*navbar mobile*/
.mobile-nav {
  position: fixed;
  top: 72px;
  /*Need to be the same as the navbar + border height*/
  right: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  background-color: var(--dark-main);
  color: #fff;
  transition: all 0.5s;

}

.mobile-nav.open {
  height: calc(105vh - 80px);
  font-size: 1em;
}

.mobile-nav a {
  display: block;
  text-align: center;
  text-decoration: none;
  color: #fff;
  list-style-type: none;
  transition: all 0.3s;
}

.mobile-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90%;
  margin-top: 0%;
}

.mobile-links a {
  display: block;
  text-decoration: none;
  color: var(--dark-text);
  transition: all .5s;
  position: relative;
}

.mobile-links hr {
  border: none;
  border-top: 3px solid var(--dark-barnav);
  width: 85%;
  transition: all 0.5s;
}

/* block the touch on IOS */
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.mobile-activbar {
  display: none;
}

@media (max-width: 768px) {

  .navbar {

    flex-direction: row;
    /* Change la direction pour la version mobile */
    justify-content: space-between;
  }

  .LogoImg::after {
    display: none;
  }

  .LogoImg:hover {
    transform: none;
  }

  .hover-3 {
    margin: 5px 10px;
  }

  .hover-3:hover {
    --_p: initial;
    --_s: initial;
    transform: none;
    transition: none;
  }

  .hamburger {
    display: block;
  }

  .links {
    display: none;
  }

  .active-bar {
    display: none;
  }

  .active {
    display: block;
  }

  .navbar .switch-name {
    display: none;
  }

  .mobile-nav {
    overflow: hidden;
  }

  .mobile-nav .switch-name {
    display: block;
    right: 5%;
    top: 7%;
  }

  .mobile-activbar {
    display: block;
    position: fixed;
    top: 70px;
    width: 100%;
    background-color: #B52A7D;
    z-index: 11;
  }

  .page-name {
    padding: 0px;
    text-align: center;
    font-size: 0.8em;
    font-weight: bold;
    color: #fff;
  }

  .links-mobile {
    font-size: 1.3em;
    font-style: bold;
    font-weight: bold;
  }

  .links-mobile2 {
    font-style: bold;
    font-weight: bold;
  }

  html[data-theme="light"] .navbar.top a,
  html[data-theme="light"] .navbar.top span {
    color: white;
    transition: all .3s;
  }

  html[data-theme="light"] .navbar.top .dropdown-arrow {
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transition: all .3s;
  }

  html[data-theme="light"] .navbar.top .logo {
    filter: none;
  }

  html[data-theme="light"] .navbar.top .dropdown-menu {
    background-color: #19B3FC;
  }

  html[data-theme="light"] .navbar.top .dropdown-menu li:hover {
    background-color: var(--dark-barnav);
  }

  html[data-theme="light"] .navbar.top .dropdown-menu li {
    color: black;
  }

  html[data-theme="light"] .navbar.top img {
    filter: invert(0);
  }

  /* Media query to remove effects on mobile */
  @media (max-width: 768px) {

    html[data-theme="light"] .navbar.top a,
    html[data-theme="light"] .navbar.top span,
    html[data-theme="light"] .navbar.top .logo,
    html[data-theme="light"] .navbar.top .dropdown-menu,
    html[data-theme="light"] .navbar.top .dropdown-menu,
    html[data-theme="light"] .navbar.top .dropdown-menu li,
    html[data-theme="light"] .navbar.top img {
      color: initial;
      transition: none;
      border: none;
      filter: none;
      background-color: initial;
    }

    html[data-theme="light"] .navbar.top .dropdown-arrow {
      border-bottom: transparent;
      border-right: 2px solid #B52A7D;
      transition: none;

    }

    html[data-theme="light"] .navbar.top .dropdown-menu li:hover {
      background-color: initial;
    }

    html[data-theme="light"] #ddmenu-mobile {
      background-color: var(--dark-main);
    }
  }


  .extra-nav {
    display: flex;
    justify-content: space-between;
  }

  .switch-name {
    border-radius: 5em;
    cursor: pointer;
    width: 80px;
    height: 40px;
    overflow: hidden;
    position: absolute;
    transform: translateY(-50%);
    transition: all .5s;
    width: 80px;
    margin-right: 5px;
  }

  .full {
    display: none;
  }

  .full:hover::before {
    transform: scaleX(0);
  }
}