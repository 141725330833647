 .react-autosuggest__container {
     position: relative;
 }

 .react-autosuggest__input {
     width: 100%;
     padding: 10px 20px;
     font-family: Helvetica, sans-serif;
     font-weight: 300;
     font-size: 16px;
     border: 1px solid #aaa;
     border-radius: 5px;
 }

 .react-autosuggest__input--focused {
     outline: none;
 }

 .react-autosuggest__input--open {
     border-bottom-left-radius: 0;
     border-bottom-right-radius: 0;
 }

 .react-autosuggest__suggestions-container {
     display: none;
 }

 .react-autosuggest__suggestions-container--open {
     display: block;
     position: absolute;
     top: 51px;
     width: 280px;
     border: 1px solid #aaa;
     background-color: #fff;
     font-family: "JakartaMedium", sans-serif;
     font-weight: 300;
     font-size: 16px;
     color: black;
     border-bottom-left-radius: 4px;
     border-bottom-right-radius: 4px;
     z-index: 2;
     max-height: 300px;
     overflow-y: auto;
 }

 .react-autosuggest__suggestions-list {
     margin: 0;
     padding: 0;
     list-style-type: none;
 }

 .react-autosuggest__suggestion {
     cursor: pointer;
     padding: 10px 20px;
 }

 .react-autosuggest__suggestion--highlighted {
     background-color: #ddd;
 }

 .react-autosuggest__section-container {
     border-top: 1px dashed #ccc;
 }

 .react-autosuggest__section-container--first {
     border-top: 0;
 }

 .react-autosuggest__section-title {
     padding: 10px 0 0 10px;
     font-size: 12px;
     color: #777;
 }