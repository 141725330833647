.ee-title {
    font-family: "JakartaExtraBold", sans-serif;
    color: var(--dark-text);
    transition: all 0.5s;
    font-size: 30px;
    text-align: center;
    letter-spacing: 2px;
    line-height: 36.7px;
    margin: 7vh 0 5vh;
    width: 100%;
}

.ee-subtitle {
    font-size: 50px;
    color: #333;
    font-family: "JakartaExtraBold", sans-serif;
    text-decoration: underline;
}

.ee-content {
    width: 80%;
    height: auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 10% auto;
    text-align: center;
}

.ee-text {
    font-family: "JakartaRegular", sans-serif;
    color: #333;
} 

.ee-owo {
    font-family: "JakartaExtraBold", sans-serif;
    color: #333;
    font-size: 50px;
    margin: 10px 0;
}