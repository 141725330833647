/* Lang.css */

.lang-button{
  display: block;
  position: relative;
  
  margin-right: 10px;
  background-color: var(--dark-extra);
  border: 2px solid var(--dark-extra);
  color: var(--dark-point);
  border-radius: 5em;
  width: 60px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, transform 0.2s;
  font-family: "JakartaBold", sans-serif; /* Police "JakarteBold" */ 
  transition: all 0.5s;
}

/****************************************************************************************************/
/*Responsive Mobile*/


@media (max-width: 768px) {

  .navbar .lang-container
  {
    display: none;
  }

  .mobile-nav .lang-container
  {
    display: block;
    right: 5%; 
    top: 7%;
  }

  .mobile-nav .lang-button
  {
    display: inline-block;
    position: absolute;
    right: 130px;
    transform: translateY(-50%);
    left: 5%;
    top: 7%;

    width: 80px;
    height: 40px;
    font-size: 25px;
  }


}