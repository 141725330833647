.comp-container-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    flex-shrink: 0;
    background-color: var(--dark-main);
    transition: all .5s;
    z-index: 2;
    overflow: hidden;
}

.comp-bg-top {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 20vh;
    overflow: hidden;
    background: url('../../../../public/img/assets/companyCover.jpg');
    background-size: cover;
    background-position: 0 60%;
}

.comp-bg-top::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../../../public/img/logo/plassys-logo.png') no-repeat center;
    background-size: contain;
    background-size: 30%;
    z-index: 2;
}

.comp-bg-top::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    background-size: cover;
}

.comp-bg-top .text {
    position: relative;
    z-index: 1;
    color: white;
}

/****************************************************************************************************/
/*Card*/
.comp-valeurs {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 2rem 0;
    width: 90%;
    height: auto;
    transition: all .5s;

}

.comp-card {
    background-color: var(--dark-secondary);
    padding: 15px;
    margin: 0 15px;
    text-align: center;
    border-radius: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    transition: all .5s;

}

.comp-card::before {
    position: absolute;
    font-size: 9em;
    color: var(--dark-contrasted);
    z-index: 1;
    font-family: JakartaLight;
    letter-spacing: -0.1em;
    transition: all .5s;
}

.comp-card:nth-child(1)::before {
    content: ".01";
}

.comp-card:nth-child(2)::before {
    content: ".02";
}

.comp-card:nth-child(3)::before {
    content: ".03";
}

.comp-card-content {
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    transition: all .5s;

}

.comp-card-title {
    margin: 0;
    padding: 10px 0;
    font-size: 24px;
    font-weight: bold;
    font-family: "JakartaExtraBold";
    color: var(--dark-text);
    transition: all .5s;

}

.comp-card-text {
    font-size: 18px;
    font-family: "JakartaRegular";
    line-height: 1.5rem;
    color: var(--dark-text);
    transition: all .5s;

}

/****************************************************************************************************/
/*Content*/

.comp-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    transition: all .5s;
}

.comp-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.comp1 {
    background-color: var(--dark-secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3vh 0;
    transition: all .5s;
    margin: 0 6%;
    margin-bottom: 4rem;
    border-radius: 15px;
}

.comp-content-img {
    flex: 1;
    padding: 18px;
}

.comp-content-img img {
    width: 100%;
    border-radius: 10px;
}

.comp-content-text {
    flex: 1;
    padding: 18px;
    color: var(--dark-text);
    font-family: JakartaRegular;
    font-size: 18px;
    line-height: 30px;
}

.comp-content-text .comp-content-anim {
    transition: all .5s;

}

.comp-content-button-container {
    display: flex;
    justify-content: right;
    width: 100%;
    height: auto;
    margin-top: 5vh;
    transition: all .5s;
}

/****************************************************************************************************/
/*Discover*/

.comp-discover {
    background-color: var(--dark-secondary);
    padding: 15px;
    margin: 2rem 0;
    border-radius: 10px;
    color: var(--dark-text);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 86%;
    height: 100%;
    transition: all .5s;
    flex: 1;
}

.comp-discover-title {
    color: var(--dark-text);
    font-family: "JakartaExtraBold";
    font-size: 1.7rem;
    text-align: center;
    transition: all .5s;
    margin: 0;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.comp-discover-link {
    margin-bottom: 1rem;

}

.comp-discover-button {
    position: relative;
    margin-top: 1%;
    background-color: var(--dark-extra3);
    color: var(--dark-text);
    transition: all .5s;
    width: auto;
    height: 3.9em;
    border: var(--dark-extra3) 0.2em solid;
    border-radius: 11px;
    text-align: right;
}

.comp-discover-button:hover {
    background-color: var(--dark-extra2);
    transition: all .5s;
    cursor: pointer;
}

.comp-discover-button svg {
    width: 2em;
    margin: -0.4em 0.8em 1em;
    position: absolute;
    display: flex;
    transition: all 0.4s ease;
    color: var(--dark-text);
    transition: all .5s;
}

.comp-discover-button:hover svg {
    transform: translateX(5px);
}

.comp-discover-button-text {
    margin: -4px;
    margin-left: 3em;
    margin-right: 1em;
    font-size: 1.2em;
    font-family: "JakartaRegular", sans-serif;
}

/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {
    .comp-valeurs {
        flex-direction: column;
        width: 100%;
        padding-bottom: 1rem;
    }

    .comp-bg-top {
        margin-top: 0.8em;
        height: 10vh;
        background-position: center;
        background-repeat: no-repeat;
    }


    .comp-bg-top::after {
        background-size: 50%;
    }

    .comp-card {
        margin-bottom: 20px;
    }

    .comp-card::before {
        font-size: 8em;
    }

    .comp-card-title {
        font-size: 20px;
        padding: 0;
    }

    .comp-card-text {
        font-size: 16px;
    }

    .comp-content {
        flex-direction: column;
        width: 100%;
    }

    .comp-content-img {
        padding-bottom: 0;
    }

    .comp-content-img img {
        width: 100%;
        height: auto;
        border-radius: 5px;
    }

    .comp-content-text {
        font-size: 16px;
        line-height: 1.5rem;
    }

    .comp-discover {
        width: 100%;
    }

    .comp-content-text {
        line-height: 1.8rem;
        padding-top: 0;
    }

    .comp1 {
        margin: 0 15px;
        margin-bottom: 2rem;
        padding: 0;
    }

    .comp-discover-title {
        font-size: 1.5rem;
    }
}