.domains {
  position: relative;
  width: 100%;
  height: auto;
  background-color: var(--dark-main);
  transition: all .5s;
}

/******************************/

.title-domain {
  width: auto;
  height: 37px;
  margin-bottom: 3%;
  margin-left: 7vmin;
  margin-right: 7vmin;
  font-family: "JakartaExtraBold", sans-serif;
  color: var(--dark-text);
  transition: all .5s;
  font-size: 20px;
  text-align: left;
  letter-spacing: 2px;
  line-height: 36.7px;
  padding-top: 2%;
}

/******************************/

.containerProd {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7vmin;
  margin-right: 7vmin;
  margin-bottom: 5vh;
  overflow: hidden;
  transform: skew(0deg);
  /*style2*/
  border-radius: 10px;
}

.containerProd .card {
  flex: 1;
  transition: all 1s ease-in-out;
  height: 70vh;
  position: relative;
  overflow: hidden;
}

.containerProd .card .card_head {
  color: white;
  background: #b52a7d;
  padding: 0.5em;
  padding-right: 1em;

  /* transform: rotate(0deg); */
  
  /* Décommenter pour style 2 */
  transform: rotate(-90deg);
  
  transform-origin: 0% 0%;
  transition: all 0.5s ease-in-out;
  min-width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 1.5em;
  white-space: nowrap;
  font-family: "JakartaBold", sans-serif;

  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.containerProd .card:hover {
  flex-grow: 7;
}

.containerProd .card:hover img {
  filter: grayscale(0);
}

.containerProd .card:hover .card_head {
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  font-size: 2em;
  transform: rotate(0deg);
  border-radius: 0;
}

.containerProd .card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: all 1s ease-in-out;
  filter: grayscale(100%);
}

/* change number of cards to display */
.containerProd .card:not(:nth-child(6)) {
  margin-right: 1em;
}

/********************************************/

.button-container {
  width: 100%;
  text-align: right;
}

.more {
  margin-right: 3.5%;
  margin-bottom: 3%;
}

.learnmore {
  position: relative;
  margin-top: 1%;
  margin-bottom: 3%;
  margin-left: 7vmin;
  background-color: var(--dark-extra);
  color: var(--dark-text);
  transition: all .5s;
  width: auto;
  height: 3.9em;
  border: var(--dark-extra) 0.2em solid;
  border-radius: 11px;
  text-align: right;
}

.learnmore:hover {
  background-color: var(--dark-extra2);
  transition: all .5s;
  cursor: pointer;
}

.learnmore svg {
  width: 2em;
  margin: -0.4em 0.8em 1em;
  position: absolute;
  display: flex;
  transition: all 0.4s ease;
  color: var(--dark-text);
  transition: all .5s;
}

.learnmore:hover svg {
  transform: translateX(5px);
}

.learnmoretext {
  margin: -4px;
  margin-left: 3em;
  margin-right: 1em;
  font-size: 1.2em;
  font-family: "JakartaRegular", sans-serif;
}

/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {

  .title-domain {
    text-align: center;
    padding: 5% 0;
    margin-left: 0;
    margin-right: 0;
  }

  .containerProd {
    flex-direction: column;
    border-radius: 20px;
    overflow: visible;
    margin-bottom: 2vh;
  }

  .containerProd .card {
    height: auto;
    margin-bottom: 1em;

  }

  .containerProd .card img {
    filter: grayscale(0);
    height: 130px;
  }

  .containerProd .card {
    width: 100%;
    border-radius: 5px;
  }

  .containerProd .card:not(:nth-child(5)) {
    margin-right: 0;
    /* Supprimer la marge à droite */
  }

  .containerProd .card:hover .card_head {
    top: calc(100% - 2em);
    color: white;
    background: rgba(0, 0, 0, 0.5);
    font-size: 1.3em;
  }

  .containerProd .card:not(:nth-child(6)) {
    margin-right: 0;
  }

  .containerProd .card .card_head {
    padding: 0.3em;
    font-size: 1.2em;
    transform: rotate(0deg);
    border-radius: 0;
  }

  .button-container {
    text-align: right;
    padding-bottom: 2vh;
  }

  .learnmore {
    border-radius: 10px;
  }

  .more {
    margin-right: 7%;
  }

}