.map-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin: 0 auto;
  flex-shrink: 0;
  background-color: var(--dark-main);
  transition: all .5s;
}

.title-map {
  width: auto;
  height: 37px;
  margin-bottom: 3%;
  margin-left: 7vmin;
  margin-right: 7vmin;
  font-family: "JakartaExtraBold", sans-serif;
  color: var(--dark-text);
  transition: all .5s;
  font-size: 20px;
  text-align: left;
  letter-spacing: 2px;
  line-height: 36.7px;
  padding-top: 2%;
  padding-bottom: 2%;
}

.map {
  fill: var(--dark-map);
  stroke: var(--dark-main);
  stroke-width: 0.5px;

  transition: all .5s;
}

.map-subtext {
  width: 90%;
  text-align: center;
  font-family: "JakartaRegular", sans-serif;
  color: var(--dark-text);
  transition: all .5s;
  line-height: 26.7px;
  margin-bottom: 5%;
  padding: 0 5%;
}

.map-default {
  outline: none;
  transition: fill 0.5s;
}

.map-default:hover {
  /*energivore en ressources CPU*/
  fill: grey;
  cursor: pointer;
}

.map-default:active {
  outline: none;
}

.map-active:hover {
  cursor: pointer;
}

.map-active:active {
  outline: none;
}

.worldmap-container {
  width: 100%;
  height: 100vh;
}

/***********************************/

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.map-tooltip {
  opacity: 0;
  animation: fadeIn 0.5s forwards;

  display: flex;
  justify-content: space-between;
  align-items: left;
  flex-direction: column;

  background-color: var(--dark-main);

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 30rem;
  height: auto;

  padding: 1em;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all .5s;
  color: var(--dark-text);
  border: 0.2em solid var(--dark-text);
}

.map-tooltip.active {
  opacity: 1;
}

.map-tooltip-titles {
  display: flex;
  justify-content: space-between;
  align-items: left;
  flex-direction: row;

  font-family: "JakartaExtraBold", sans-serif;
  font-size: 1.5em;
  text-align: left;
  margin-bottom: 1em;
}

.map-tooltip-close {
  font-family: "JakartaExtraBold", sans-serif;
  text-align: center;
  cursor: pointer;
  padding: 0.3em;
  border-radius: 5px;
  background-color: var(--dark-extra);
  color: var(--dark-text);
  width: 20px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  color: white;
  display: inline-block;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.map-tooltip-close:hover {
  background-color: var(--dark-barnav);
}

.map-tooltip-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  height: 100%;
  gap: 1em;

}

.map-tooltip-content-text {
  font-family: "JakartaMedium", sans-serif;
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 1em;

  display: flex;
  flex-direction: column;

}

.map-tooltip-content-chart {
  width: 100%;
  height: 50vh;
}

.map-tooltip-nochart {
  font-family: "JakartaBold", sans-serif;
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 1em;
}

/***********************************/
.hover-tooltip {
  position: absolute;
  background-color: var(--dark-extra);
  color: var(--dark-text);
  font-family: "JakartaMedium", sans-serif;
  font-size: 1.1em;
  padding: 10px;
  border-radius: 10px;
  pointer-events: none;
  z-index: 1000;
}


/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 1200px) {
  .worldmap-container {
    height: 50vh;
  }

  .map-tooltip {
    width: auto;

    display: flex;
    justify-content: none;
    align-items: left;
    flex-direction: column;
  }

  .map-tooltip-content {
    flex-direction: row;
    width: 100%;
  }

  .map-tooltip-content-chart {
    height: 40vh;
  }

  .map-tooltip-content-text {
    font-size: 1em;
    white-space: nowrap;
    text-align: left;
  }

  .map-tooltip-nochart {
    flex-direction: column;
  }
  

}

@media (max-width: 768px) {
  .title-map {
    text-align: center;
    padding: 5%;
    padding-bottom: 0;
  }
}