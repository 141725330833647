.part-container {
    margin: 0 auto;
    width: 100%;
    height: auto;
    background-color: black;
    align-items: center;
    padding-bottom: .5%;
    padding-top: .5%;
}

.part-carousel-container {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    align-items: center;
}

.part-title {
    color: #fff;
    text-align: center;
    margin: 0 auto;
    font-family: "JakartaBold";
    letter-spacing: 2px;
    line-height: 36.7px;
    font-size: 20px;
    padding-top: 0.5%;
}

.marque-img-container {
    margin: 0 auto;
    width: 80%;
    height: 100%;
}

.marque-img {
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {
    .part-container {
        padding-bottom: 1vh;
        padding-top: 1vh;
    }

    .part-title {
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 1vh;
    }

    .part-carousel-mobile
    {
        height: 100%;
        display: flex;  
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap : 20px;
    }

    .part-carousel-container {
        margin: 0 auto;
        width: 100%;
        height: 100%;
    }

    .marque-img {
        height: auto;
        margin: 0 auto;
        position: relative;
        top: 0;
        transform: translateY(0%);
    }
}