.diamtek-container-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    flex-shrink: 0;
    background-color: var(--dark-main);
    transition: all .5s;
    z-index: 2;
    overflow: hidden;
}

/****************************************************/
/*body diamtek*/
.diamtek-body {
    padding: 5%;
}

/****************************************************/
/* diamtek timeline*/

.diamtek-timeline {
    margin-top: 3%;
    margin-bottom: 3%;
    background-color: var(--dark-secondary);
    border-radius: 1rem;
    color: var(--dark-text);
    overflow: hidden;
    transition: all .5s;
    padding: 1rem;

}

.diamtek-timeline-title {
    font-family: "JakartaExtraBold";
    font-size: 1.7rem;
    color: var(--dark-text);
    text-align: left;
    padding: 1rem;
    transition: all .5s;

}

.diamtek-timeline-content {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 2rem;
    height: 100%;
    gap: 2rem;
}

.diamtek-timeline-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 100%;
    height: 100%;
    transition: all .5s;

    color: var(--dark-text);
    font-family: "JakartaMedium";
    font-size: 1.2rem;
    line-height: 2rem;
}

.diamtek-timeline-date .important {
    font-family: "JakartaExtraBold";
    color: var(--dark-important);
}

.diamtek-timeline-image {
    width: 100%;
    background-image: url('./../../../../public/img/assets/timelinediamtek.jpg');
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all .5s;
}

/****************************************************/
/* diamtek gallery*/

.diamtek-gallery {
    width: 100%;
}

/****************************************************/
/* diamtek go to page*/

.diamtek-gotoCVD-container {
    margin-top: 3%;
    display: flex;
    justify-content: right;
    align-items: center;
}


/****************************************************/
/* diamtek annexe*/

.diamtek-annexe {
    margin-top: 3%;
    background-color: var(--dark-secondary);
    border-radius: 1rem;
    color: var(--dark-text);
    overflow: hidden;
    transition: all .5s;
    padding: 1rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: auto;
}

.diamtek-annexe-document {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex: 1;
    margin-bottom: 1rem;
}

.diamtek-annexe-document-title {
    font-family: "JakartaExtraBold";
    font-size: 1.5rem;
    color: var(--dark-text);
    text-align: left;
    padding: 1rem;
    transition: all .5s;
}

.diamtek-annexe-document-download-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.diamtek-annexe-document-download-list hr {
    border: 2px solid var(--dark-text);
    width: 100%;
    border-radius: 1rem;
    transition: all .5s;
    align-self: center;
}

.diamtek-annexe-document-download-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: all .5s;

    color: var(--dark-text);
    font-family: "JakartaMedium";
    font-size: 1.1rem;
}

.diamtek-annexe-document-download-item-title {
    padding: 1vh 1rem;
}

.diamtek-annexe-document-download-item-button {
    color: var(--dark-download);
    transition: all .5s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vh 1rem;
    gap: 0.5rem;
    text-decoration: none;
}

.diamtek-annexe-document-download-item-button:hover {
    color: var(--dark-text);
}

.diamtek-annexe-document-download-item-button-icon {
    width: 1.4rem;
    height: 1.4rem;
    background-image: url('./../../../../public/img/icones/pdf.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all .5s;
    filter: var(--dark-img);

}


/****************************************************/
/*diamtek contact*/

.diamtek-gotoCVD-button {
    background-color: var(--dark-extra) !important;
    border: var(--dark-extra2) 0.2em solid !important;
}

.diamtek-gotoCVD-button:hover {
    background-color: var(--dark-extra2) !important;
    cursor: pointer;
}

.diamtek-annexe-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    transition: all .5s;
    flex: 1;
}

.diamtek-annexe-contact-text {
    color: var(--dark-text);
    font-family: "JakartaExtraBold";
    font-size: 1.5rem;
    text-align: center;
    padding: 1rem;
    transition: all .5s;
}

.diamtek-annexe-contact-button {
    position: relative;
    margin-top: 1%;
    background-color: var(--dark-extra3);
    color: var(--dark-text);
    transition: all .5s;
    width: auto;
    height: 3.9em;
    border: var(--dark-extra3) 0.2em solid;
    border-radius: 11px;
    text-align: right;
}

.diamtek-annexe-contact-button:hover {
    background-color: var(--dark-extra2);
    transition: all .5s;
    cursor: pointer;
}

.diamtek-annexe-contact-button svg {
    width: 2em;
    margin: -0.4em 0.8em 1em;
    position: absolute;
    display: flex;
    transition: all 0.4s ease;
    color: var(--dark-text);
    transition: all .5s;
}

.diamtek-annexe-contact-button:hover svg {
    transform: translateX(5px);
}

.diamtek-annexe-contact-button-text {
    margin: -4px;
    margin-left: 3em;
    margin-right: 1em;
    font-size: 1.2em;
    font-family: "JakartaRegular", sans-serif;
}

/****************************************************/
/* diamtek line*/
.diamtek-annexe-line {
    align-self: stretch;
    width: 0;
    border-left: 4px solid var(--dark-text);
    transition: all .5s;
    color: var(--dark-text);
    margin: 0 1rem;
    border-radius: 1rem;
}

@media (max-width: 768px) {

    .diamtek-body {
        padding: 3%;
    }

    .diamtek-technology {
        margin-top: 5vh;
    }

    .diamtek-timeline {
        margin-top: 5vh;
        margin-bottom: 5vh;
    }

    .diamtek-annexe {
        flex-direction: column;
        margin-top: 5vh;
        margin-bottom: 3vh;
    }

    /*******************/

    .diamtek-listhadware {
        flex-direction: column;
        gap: 1rem;
        margin: 0;
        margin-bottom: 2vh;
        border-radius: 0pc;
    }

    .diamtek-hardware-arrow {
        height: 7vh;
    }

    .diamtek-systems {
        height: auto;
        gap: 2rem;
    }

    .diamtek-systems .diamtek-systems-row:first-child {
        margin-top: 20px;
    }

    .diamtek-systems .diamtek-systems-row:last-child {
        margin-bottom: 20px;
    }

    .diamtek-systems-row {
        flex-direction: column;
        margin: 0 5vw;
    }

    .diamtek-systems-item-title {
        font-size: 1.5rem;
    }

    .diamtek-systems-item-text {
        font-size: 14px;
    }

    /*******************/
    /* diamtek timeline */
    .diamtek-timeline-content {
        flex-direction: column;
        margin: 0;
        gap: 0;
    }

    .diamtek-timeline-date {
        font-size: 1rem;
    }

    .diamtek-timeline-date ul {
        padding-left: 15px;
    }

    .diamtek-timeline-title {
        font-size: 1.5rem;
        text-align: center;
        padding: 0;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .diamtek-timeline-image {
        height: 51vh;
    }

    /*******************/
    /* diamtek annexe */

    .diamtek-annexe-contact {
        width: 100%;
        margin-bottom: 2vh;
    }

    .diamtek-annexe-line {
        border-left: none;
        border-top: 1px solid var(--dark-text);
        height: 0;
        width: 200px;
        margin: 0.5rem auto;
    }

    .diamtek-annexe-document-download-item-button {
        padding: 1vh 0.5rem;
    }

    .diamtek-annexe-document-download-item-button-icon {
        width: 1.2rem;
        height: 1.2rem;
    }

    .diamtek-annexe-document-download-item-title {
        padding: 1vh 0.5rem;
    }

    .diamtek-annexe-document-download-item {
        align-items: center;
    }

    .diamtek-annexe-contact-button {
        width: 100%;
    }

    .diamtek-annexe-contact-button svg {
        margin: -0.4em 0.8em 1em;
    }

    .diamtek-annexe-contact-text {
        font-size: 1.2rem;
        padding: 0;
    }

    .diamtek-annexe-document-title {
        font-size: 1.5rem;
        text-align: center;
    }

    .diamtek-annexe-document-download-item {
        font-size: 1rem;
    }

    .diamtek-annexe-document-download-item-button-icon {
        width: 1rem;
        height: 1rem;
    }

    .diamtek-annexe-document-download-item-title {
        font-size: 1rem;
    }

    .diamtek-gotoCVD-container {
        justify-content: center;
        margin-top: 5vh;
    }

    .diamtek-gotoCVD-button {
        width: auto;
    }

}