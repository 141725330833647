.bd-bg-top {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: 20vh;
    overflow: hidden;

    /*background-image: in props of the component*/
    background-size: cover;
    background-position: center;
}


.bd-bg-top::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    background-size: cover;
    z-index: 0;
}

.bd-bg-top .bd-bg-top-img,
.bd-bg-top .bd-bg-top-text {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    color: white;
}

.bd-bg-top .bd-bg-top-text {
    font-size: 1.5rem;
    font-family: "JakartaBold";
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0.4rem;
}

.bd-bg-top .bd-bg-top-img {
    width: auto;
    height: 10vh;
}

/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {
    .bd-bg-top {
        height: 170px;
    }

    .bd-bg-top .bd-bg-top-img {
        margin-top: 15%;
        height: 8vh;
    }

    .bd-bg-top .bd-bg-top-text {
        font-size: 1.2rem
    }

}