/******************************/
/* contact info */
.contact-info-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 30px;
    color: var(--dark-text);
    transition: all 0.5s;
    border: 1px solid var(--dark-text);
    background-color: var(--dark-secondary);
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
}

.contact-info-country {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    color: var(--dark-text);
    transition: all 0.5s;
}

.contact-info-country img {
    flex: 0.2;
    width: 50px;
    margin-right: 10px;
    border-radius: 10px;
    transition: all 0.5s;
}

.contact-info-country h2 {
    width: 100%;
    flex: 0.8;
    font-size: 2rem;
    font-family: "JakartaBold", sans-serif;
    color: var(--dark-text);
    transition: all 0.5s;
    margin: 0;
}

.info {
    width: 100%;
    margin-bottom: 15px;
    color: var(--dark-text);
    transition: all 0.5s;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.info img {
    flex: 0.3;
    max-width: 20px;
    margin: 0 3% 0 2%;
    filter: var(--dark-img2);
    transition: all 0.5s;
}

.info label {
    width: 100%;
    font-family: "JakartaMedium", sans-serif;
    font-size: 1rem;
    color: var(--dark-text);
    transition: all 0.5s;
}

.cursor {
    cursor: pointer;
}

/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {


    .contact-info-card {
        margin-bottom: 10px;
    }

    .contact-info-coord {
        flex: 1;
    }
    
    .contact-info-country img {
        width: 30px;
        border-radius: 5px;
    }

    .contact-info-country h2 {
        font-size: 1.5rem;
    }

    .info img {
        max-width: 15px;
    }

    .info label {
        font-size: 0.8rem;
    }
  
}