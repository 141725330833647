.filtre-sombre {
  position: relative;
  height: 900px;
  width: 100%;
  background-color: var(--dark-main);
  font-family: "JakartaRegular", sans-serif;
  transition: all .5s;
}

#video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  z-index: 0;
  opacity: 0.7;
}

.img-vid-de-fond {
  position: relative;
  width: 100%;
  height: 900px;
  top: 0;
  left: 0;

  /*remplace la vidéo*/
  /*background: url(../../../../public/img/assets/vid.png);
  background-size: cover;
  background-position: 50% 50%;*/

}

.dgrad-wrapper {
  position: relative;
  height: 900px;
  background-color: #00000066;
  z-index: 1;
}


.dgrad {
  width: 100%;
  height: 900px;
  background: linear-gradient(180deg,
      rgba(0, 30.62, 63, 0) 87.39%,
      var(--dark-secondary) 100%);
  z-index: 0;
}

.text-vitrine {
  position: absolute;
  width: 100%;
  height: auto;
  gap: 35px;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  overflow-wrap: break-word;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flexcontainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 29px;
  height: auto;
  font-family: "JakartaRegular", sans-serif;
  font-weight: 400;
}

.text {
  position: relative;
  align-self: center;
  font-weight: 400;
  color: white;
  font-size: 40px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 24.7px;
}

.span {
  font-weight: 400;
  color: var(--dark-text);
  font-size: 40px;
  letter-spacing: 2px;
  line-height: 24.7px;
  transition: all .5s;
}

.logoTransparent {
  position: relative;
  background-image: url(../../../../public/img/logo/plassys-logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 512px;
  height: 163px;
  object-fit: cover;
}

/***************************************/
/*Dessin*/
.dessin {
  position: relative;
  margin-top: 1%;
  width: 630px;
  height: 50px;
}
/* .dessin {
  position: relative;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* .rectangle {
  position: absolute;
  width: 35px;
  height: 5px;
  top: 100%;
  left: 50.05%;
  transform: translate(-50%, -50%) rotate(90deg);
  background-color: #ffffff;
  border-radius: 13px;
}

.rectangle-2 {
  position: absolute;
  width: 630px;
  height: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 13px;
} */

/***************************************/
/*Arrow scroll*/
.arrow-scroll {
  position: absolute;
  margin-top: 25%;
  left: 49.5%;
  cursor: pointer;
}

.arrow-scroll span {
  display: block;
  width: 1.5vw;
  height: 1.5vw;
  border-bottom: 5px solid white;
  border-right: 5px solid white;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow-scroll span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow-scroll span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {

  .logoTransparent {
    width: 100%;
    height: 82px;
    background-position: center;
  }

  .text-vitrine {
    gap : 10px;
  }

  .filtre-sombre {
    height: 90vh;
  }

  .flexcontainer {
    width: 100vw;
    gap: 0
  }

  .text {
    /* white-space: nowrap; */
    font-size: 18px;
    /* line-height: 20px; */
    letter-spacing: 1px;
  }

  .dessin {
    display: none;
  } 

  /* .rectangle,
  .rectangle-2 {
    height: 8px;
  }

  .rectangle {
    left: 53%;
  } */

  .arrow-scroll {
    margin-top: 50%;
    left: 49%;
  }

  .arrow-scroll span {
    width: 7vw;
    height: 7vw;
    border-bottom: 3px solid white;
    border-right: 3px solid white;
    margin: -10px;
  }

}