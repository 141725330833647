.vertical-timeline * {
    box-sizing: border-box
}

.vertical-timeline {
    width: 95%;
    max-width: 1170px;
    margin: 0 auto;
    position: relative;
    padding: 2em 0
}

.vertical-timeline::after {
    content: '';
    display: table;
    clear: both
}

.vertical-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: var(--line-color)
}

.vertical-timeline.vertical-timeline--one-column-right::before {
    right: 18px;
    left: unset
}


.vertical-timeline-element {
    position: relative;
    margin: 2em 0
}

.vertical-timeline-element>div {
    min-height: 1px
}

.vertical-timeline-element:after {
    content: "";
    display: table;
    clear: both
}

.vertical-timeline-element:first-child {
    margin-top: 0
}

.vertical-timeline-element:last-child {
    margin-bottom: 0
}

.vertical-timeline-element-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, .08), 0 3px 0 4px rgba(0, 0, 0, .05)
}

.vertical-timeline--one-column-right .vertical-timeline-element-icon {
    right: 0;
    left: unset
}

.vertical-timeline-element-icon svg {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px
}

.vertical-timeline--animate .vertical-timeline-element-icon.is-hidden {
    visibility: hidden
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 .6s;
    -moz-animation: cd-bounce-1 .6s;
    animation: cd-bounce-1 .6s
}

@-webkit-keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(.5)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(1.2)
    }

    100% {
        -webkit-transform: scale(1)
    }
}

@-moz-keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -moz-transform: scale(.5)
    }

    60% {
        opacity: 1;
        -moz-transform: scale(1.2)
    }

    100% {
        -moz-transform: scale(1)
    }
}

@keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(.5);
        -moz-transform: scale(.5);
        -ms-transform: scale(.5);
        -o-transform: scale(.5);
        transform: scale(.5)
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2)
    }

    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

.vertical-timeline-element-content {
    position: relative;
    margin-left: 30px;
    background: #fff;
    border-radius: .25em;
    padding: 1em;
    box-shadow: 0 3px 0 #ddd
}

.vertical-timeline--one-column-right .vertical-timeline-element-content {
    margin-right: 60px;
    margin-left: unset
}

.vertical-timeline-element--no-children .vertical-timeline-element-content {
    background: 0 0;
    box-shadow: none
}

.vertical-timeline-element-content:after {
    content: "";
    display: table;
    clear: both
}

.vertical-timeline-element-content h2 {
    color: #303e49
}

.vertical-timeline-element-date {
    display: none !important;
}

.vertical-timeline-element-content .vertical-timeline-element-date {
    display: inline-block
}

.vertical-timeline-element-content p {
    margin: 1em 0 0;
    line-height: 1.6
}

.vertical-timeline-element-title {
    margin: 0
}

.vertical-timeline-element-subtitle {
    margin: 0
}

.vertical-timeline-element-content .vertical-timeline-element-date {
    float: left;
    padding: .8em 0;
    opacity: .7
}

.vertical-timeline-element-content-arrow {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #fff
}

.vertical-timeline--one-column-right .vertical-timeline-element-content-arrow {
    content: '';
    position: absolute;
    top: 16px;
    right: unset;
    left: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-left: 7px solid #fff
}

.vertical-timeline--one-column-right .vertical-timeline-element-content::before {
    left: 100%;
    border-left: 7px solid #fff;
    border-right: unset
}

.vertical-timeline-element--no-children .vertical-timeline-element-content::before {
    display: none
}

.vertical-timeline-element--no-children .vertical-timeline-element-content-arrow {
    display: none
}

.vertical-timeline--animate .vertical-timeline-element-content.is-hidden {
    visibility: hidden
}

.vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 .6s;
    -moz-animation: cd-bounce-2 .6s;
    animation: cd-bounce-2 .6s
}


@-webkit-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px)
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(20px)
    }

    100% {
        -webkit-transform: translateX(0)
    }
}

@-moz-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -moz-transform: translateX(-100px)
    }

    60% {
        opacity: 1;
        -moz-transform: translateX(20px)
    }

    100% {
        -moz-transform: translateX(0)
    }
}

@keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        -moz-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        -o-transform: translateX(-100px);
        transform: translateX(-100px)
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px)
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0)
    }
}

@-webkit-keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px)
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px)
    }

    100% {
        -webkit-transform: translateX(0)
    }
}

@-moz-keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -moz-transform: translateX(100px)
    }

    60% {
        opacity: 1;
        -moz-transform: translateX(-20px)
    }

    100% {
        -moz-transform: translateX(0)
    }
}

@keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
        -moz-transform: translateX(100px);
        -ms-transform: translateX(100px);
        -o-transform: translateX(100px);
        transform: translateX(100px)
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px)
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0)
    }
}

/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {

    .vertical-timeline {
        width: 100%;
    }

    .vertical-timeline::before {
        left: 16px;
    }

    .vertical-timeline-element-content {
        width: 90%;
        margin-left: 50px;
    }
}