.banner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;
    height: 190px;

    background-size: cover;
    background-position: center;

    transition: all .5s;
    text-align: center;
    width: 100%;
    overflow: hidden;
}


/************************************/
/* Banner Image */

.banner-image {
    width: 100%;
    height: 60%;
    flex: 0.8;
    object-fit: cover;
}

.banner-image img {
    width: auto;
    height: 12vh;
    object-fit: contain;
}


/************************************/
/* Banner Content */

.banner-content {
    font-style: normal;
    color: #fff;
    flex: 1.2;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-right: 3.5%;
}

.banner-content .banner-text {
    width: 100%;
    height: 100%;
    flex-grow: 1;

}

.banner-content .banner-title {
    font-family: "JakartaExtraBold";
    text-align: left;
    font-size: 1.7rem;
    height: auto;
}


/************************************/
/* Banner Button */

.banner-button {
    text-align: right;
    align-self: flex-end;
}

.banner-button-discover {
    position: relative;
    background-color: var(--dark-extra);
    color: var(--dark-text);
    transition: all .5s;
    width: auto;
    height: 3.9em;
    border: var(--dark-extra) 0.2em solid;
    border-radius: 11px;
    text-align: right;
}

.banner-button-discover:hover {
    background-color: var(--dark-extra2);
    transition: all .5s;
    cursor: pointer;
}

.banner-button .more {
    margin-right: 5%;
    margin-bottom: 3%;
}



.banner-button-discover svg {
    width: 2em;
    margin: -0.4em 0.8em 1em;
    position: absolute;
    display: flex;
    transition: all 0.4s ease;
    color: var(--dark-text);
    transition: all .5s;
}

.banner-button-discover:hover svg {
    transform: translateX(5px);
}

.banner-button-discover-text {
    margin: -4px;
    margin-left: 3em;
    margin-right: 1em;
    font-size: 1.2em;
    font-family: "JakartaRegular", sans-serif;
}



/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {
    .banner-container {
        flex-direction: column;
        height: auto;
        gap: 10px;
    }

    .banner-image {
        margin-top: 3vh;
        flex: 0.7;
    }

    .banner-image img {
        height: 10vh;
    }

    .banner-content {
        padding: 0;
        width: 100%;
        gap: 20px;
    }

    .banner-content .banner-title {
        text-align: center;
        font-size: 1.2rem;
    }
    
    .banner-text {
        margin-bottom: 0;
    }

    .banner-button {
        margin-right: 7vw;
        margin-bottom: 2vh;
    }

    
}
