/****************************************************/
/*history*/

.history-container {
    background-color: var(--dark-secondary);
    border-radius: 1rem;
    color: var(--dark-text);
    overflow: hidden;
    transition: all .5s;
}

.history-top {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 2rem;
    margin-left: 2rem;
    height: 100%;
    gap: 2rem;
}

/************************************/
/*history*/

/****************************/
/*history left top*/
.history-bg-top-left {
    width: 100%;
    flex: 1;
    align-items: flex-start;
}

.history-top-left-titles {
    width: 100%;
    margin-top: 1.5rem;
    font-family: "JakartaExtraBold";
}

.history-top-left-title {
    font-size: 2rem;
    margin: 0;
    position: relative;
    display: inline-block;
}

.history-top-left-title span {
    font-size: 0.8rem;
    position: absolute;
    top: 0;
}

.history-top-left-subtitle {
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
}



/****************************/
/*history right top*/
.history-top-right {
    position: relative;
    /*background-image: in props of the component*/
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 50%;
    min-width: 50%;
    min-height: 35vh;
    flex: 1;
}

.history-top-right::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-image: linear-gradient(to top, var(--dark-secondary), transparent), linear-gradient(to right, var(--dark-secondary), transparent);
    background-repeat: no-repeat;
    background-size: 100% 100px, 100px 100%;
    background-position: bottom, left;
    transition: all .5s;
}

/****************************/
/*history bottom*/

.history-bottom {
    margin: 2rem;
}

.history-bottom-text {
    font-size: 18px;
    font-family: "JakartaRegular";
    line-height: 1.7rem;
    color: var(--dark-text);
    transition: all .5s;
    text-align: justify;
}


/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {
    .history-container {
        width: 100%;
        margin-top: 3vh;
    }

    .history-top, .history-bottom {
        flex-direction: column;
        margin: 0;
        gap: 0;
    }

    .history-bottom-text {
        padding: 10px;
    }

    .history-top-left-titles {
        margin-top: 0.5rem;
        text-align: center;
    }

    .history-top-left-title {
        font-size: 1.9rem;
    }
    
    .history-top-left-subtitle {
        font-size: 1rem;
    }

    .history-bottom-text {
        font-size: 16px;
        line-height: 25px;
        text-align: left;
    }

    .history-top-right {
        max-width: 100%;
        min-width: 100%;
        min-height: 35vh;
    }

    .history-top-right::before {
        background-image: linear-gradient(to top, var(--dark-secondary), transparent), linear-gradient(to bottom, var(--dark-secondary), transparent);
        background-repeat: no-repeat;
        background-size: 100% 100px, 100% 100px;
        background-position: bottom, top;
    }

}