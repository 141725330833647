/*import woff font*/
@font-face {
    font-family: "JakartaBold";
    src: url("../public/font/PlusJakartaSans-Bold.woff") format("woff");
  }
  @font-face {
    font-family: "JakartaBoldItalic";
    src: url("../public/font/PlusJakartaSans-BoldItalic.woff") format("woff");
  }
  @font-face {
    font-family: "JakartaExtraBold";
    src: url("../public/font/PlusJakartaSans-ExtraBold.woff") format("woff");
  }
  @font-face {
    font-family: "JakartaExtraBoldItalic";
    src: url("../public/font/PlusJakartaSans-ExtraBoldItalic.woff") format("woff");
  }
  @font-face {
    font-family: "JakartaExtraLight";
    src: url("../public/font/PlusJakartaSans-ExtraLight.woff") format("woff");
  }
  @font-face {
    font-family: "JakartaExtraLightItalic";
    src: url("../public/font/PlusJakartaSans-ExtraLightItalic.woff") format("woff");
  }
  @font-face {
    font-family: "JakartaItalic";
    src: url("../public/font/PlusJakartaSans-Italic.woff") format("woff");
  }
  @font-face {
    font-family: "JakartaLight";
    src: url("../public/font/PlusJakartaSans-Light.woff") format("woff");
  }
  @font-face {
    font-family: "JakartaLightItalic";
    src: url("../public/font/PlusJakartaSans-LightItalic.woff") format("woff");
  }
  @font-face {
    font-family: "JakartaMedium";
    src: url("../public/font/PlusJakartaSans-Medium.woff") format("woff");
  }
  @font-face {
    font-family: "JakartaMediumItalic";
    src: url("../public/font/PlusJakartaSans-MediumItalic.woff") format("woff");
  }
  @font-face {
    font-family: "JakartaRegular";
    src: url("../public/font/PlusJakartaSans-Regular.woff") format("woff");
  }
  @font-face {
    font-family: "JakartaSemiBold";
    src: url("../public/font/PlusJakartaSans-SemiBold.woff") format("woff");
  }
  @font-face {
    font-family: "JakartaSemiBoldItalic";
    src: url("../public/font/PlusJakartaSans-SemiBoldItalic.woff") format("woff");
  }