.qutekHWItem-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 100px;
    height: 100%;
    min-height: 100px;
    padding: 1rem;
    border-radius: 0.5rem;

    /*background-image: in props of the component*/
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.qutekHWItem-title {
    font-family: "JakartaExtraBold";
    font-size: 1.5rem;
    color: white;
    text-align: center;
}


/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {
    .qutekHWItem-card {
        border-radius: 0;
        width: 100%;
        padding: 0;
    }

    .qutekHWItem-title {
        font-size: 1.4rem;
    }

}