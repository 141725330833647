.dalek-container-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    flex-shrink: 0;
    background-color: var(--dark-main);
    transition: all .5s;
    z-index: 2;
    overflow: hidden;
}

/****************************************************/
/*body dalek*/
.dalek-body {
    padding: 5%;
}

/****************************************************************************************************/
/*Card*/
.dalek-title-card {
    font-family: "JakartaExtraBold";
    font-size: 2rem;
    color: var(--dark-text);
    text-align: center;
    padding-top: 1rem;
    margin-bottom: 0px;
    transition: all .5s;
}

.dalek-cards {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 2rem 0;
    width: 100%;
    height: auto;
    transition: all .5s;
}

.dalek-card-title {
    font-family: "JakartaExtraBold";
    font-size: 1.5rem;
    color: var(--dark-text);
    text-align: center;
    padding-bottom: 1rem;
    transition: all .5s;
}

.dalek-card {
    background-color: var(--dark-secondary);
    padding: 20px;
    text-align: justify;
    border-radius: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    transition: all .5s;
}

.dalek-card-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s;
}

.dalek-card-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    color: var(--dark-text);
    transition: all .5s;
}

.dalek-card::before {
    position: absolute;
    font-size: 9em;
    color: var(--dark-contrasted);
    z-index: 1;
    font-family: JakartaLight;
    letter-spacing: -0.1em;
    transition: all .5s;
}

.dalek-card:nth-child(1)::before {
    content: ".01";
}

.dalek-card:nth-child(2)::before {
    content: ".02";
}

.dalek-card:nth-child(3)::before {
    content: ".03";
}

/****************************************************/
/* dalek feature*/

.dalek-features {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1rem 0;
    width: 100%;
    height: auto;
    transition: all .5s;
}

.dalek-title-feature {
    font-family: "JakartaExtraBold";
    font-size: rem;
    color: var(--dark-text);
    text-align: center;
    margin-bottom: 1.5rem;
    transition: all .5s;
}

.dalek-features-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    transition: all .5s;
}

.dalek-features-text-list {
    font-family: "JakartaRegular";
    font-size: 18px;
    color: var(--dark-text);
    line-height: 1.5rem;
    transition: all .5s;
    background-color: var(--dark-secondary);
    border-radius: 10px;
    padding: 20px;
    height: 100%;
    flex-grow: 1;
}

.dalek-features-right, .dalek-features-left {
    gap: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dalek-features-right-text, .dalek-features-left-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.dalek-features-right-image, .dalek-features-left-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dalek-features-right-image img, .dalek-features-left-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

/****************************************************/
/* dalek annexe*/

.dalek-annexe {
    margin: 0 auto;
    margin-top: 3%;
    background-color: var(--dark-secondary);
    border-radius: 1rem;
    color: var(--dark-text);
    overflow: hidden;
    transition: all .5s;
    padding: 1rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    width: 40%;
}

.dalek-annexe-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    transition: all .5s;
    width: 100%;
    padding: 1rem;
}

.dalek-annexe-contact-text {
    color: var(--dark-text);
    font-family: "JakartaExtraBold";
    font-size: 1.5rem;
    text-align: center;
    transition: all .5s;
    margin-top: 0 ;
}

.dalek-annexe-contact-button {
    position: relative;
    margin-top: 1%;
    background-color: #ffffff00;
    color: var(--dark-text);
    transition: all .5s;
    width: auto;
    height: 3.9em;
    border: #3654ff 0.2em solid;
    border-radius: 11px;
    text-align: right;
}

.dalek-annexe-contact-button:hover {
    background-color: var(--dark-secondary);
    transition: all .5s;
    cursor: pointer;
}

.dalek-annexe-contact-button svg {
    width: 2em;
    margin: -0.4em 0.8em 1em;
    position: absolute;
    display: flex;
    transition: all 0.4s ease;
    color: var(--dark-text);
    transition: all .5s;
}

.dalek-annexe-contact-button:hover svg {
    transform: translateX(5px);
}

.dalek-annexe-contact-button-text {
    margin: -4px;
    margin-left: 3em;
    margin-right: 1em;
    font-size: 1.2em;
    font-family: "JakartaRegular", sans-serif;
}

/****************************************************/
/* dalek line*/
.dalek-annexe-line {
    align-self: stretch;
    width: 0;
    border-left: 4px solid var(--dark-text);
    transition: all .5s;
    color: var(--dark-text);
    margin: 0 1rem;
    border-radius: 1rem;
}

@media (max-width: 768px) {

    .dalek-body {
        padding: 3%;
    }

    .dalek-annexe {
        flex-direction: column;
        margin-top: 5vh;
        margin-bottom: 3vh;
    }

    /*******************/
    /* dalek cards */

    .dalek-title-card {
        font-size: 1.5rem;
    }
    
    .dalek-cards {
        flex-direction: column;
        width: 100%;
    }
    
    .dalek-card::before {
        font-size: 6em;
    }

    /*******************/
    /* dalek features */

    .dalek-title-feature {
        font-size: 1.5rem;
    }

    .dalek-features-container {
        flex-direction: column;
        gap: 2rem;
    }

    .dalek-features-text-list {
        font-size: 1rem;
    }

    .dalek-features-text-list ul {
        padding: 5vw;
        margin: 0;
    }
    
    .dalek-features-right, .dalek-features-left {
        flex-direction: column;
        gap: 2rem;
    }

    .dalek-features-right-text, .dalek-features-left-text {
        gap: 1rem;
    }

    .dalek-features-right-image, .dalek-features-left-image {
        justify-content: center;
    }

    .dalek-features-right-image img, .dalek-features-left-image img {
        max-width: 100%;
        height: auto;
        border-radius: 10px;
    }


    /*******************/
    /* dalek annexe */

    .dalek-annexe {
        width: 85vw;
    }

    .dalek-annexe-contact {
        width: 100%;
        margin-bottom: 2vh;
        margin-top: 0;
    }

    .dalek-annexe-line {
        border-left: none;
        border-top: 1px solid var(--dark-text);
        height: 0;
        width: 200px;
        margin: 1rem auto;
    }

    .dalek-annexe-document-download-item-button {
        padding: 1vh 0.5rem;
    }

    .dalek-annexe-document-download-item-button-icon {
        width: 1.2rem;
        height: 1.2rem;
    }

    .dalek-annexe-document-download-item-title {
        padding: 1vh 0.5rem;
    }

    .dalek-annexe-document-download-item {
        align-items: center;
    }

    .dalek-annexe-contact-button {
        width: 100%;
    }

    .dalek-annexe-contact-button svg {
        margin: -0.4em 0.8em 1em;
    }

    .dalek-annexe-contact-text {
        font-size: 1.2rem;
        padding: 0;
    }

    .dalek-annexe-document-title {
        font-size: 1.5rem;
        text-align: center;
    }

    .dalek-annexe-document-download-item {
        font-size: 1rem;
    }

    .dalek-annexe-document-download-item-button-icon {
        width: 1rem;
        height: 1rem;
    }

    .dalek-annexe-document-download-item-title {
        font-size: 1rem;
    }

    .dalek-gotoCVD-container {
        justify-content: center;
        margin-top: 5vh;
    }

    .dalek-gotoCVD-button {
        width: auto;
    }

}