.dropdown-parent {
    position: relative;
}

.dropdown-menu {
    position: absolute;
    top: 54px;
    left: -11px;
    width: auto;
    background-color: var(--dark-main);
    border: 3px solid var(--dark-barnav);
    border-radius: 15px;
    padding: 10px;
    z-index: 1;
    color: var(--dark-text);
    transition: all 0.5s;
    text-decoration: none;

}

.dropdown-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;
    width: 100%;
    gap: 10px;
}

.dropdown-arrow {
    border-bottom: 2px solid var(--dark-text);
    border-right: 2px solid var(--dark-text);
    position: relative;
    width: 10px;
    height: 10px;
    transform: rotate(45deg) translateY(30%);
    transform-origin: right;
    transition: all 0.5s;

}

.dropdown-parent:hover .dropdown-arrow {
    transform: rotate(-45deg) scaleY(-1);
}

.dropdown-menu-item {
    list-style: none;
    padding: 0px 10px;
}

.dropdown-menu-item-link {
    text-decoration: none;
    color: var(--dark-text);
    transition: all 0.5s;
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px 0px;
    transition: all 0.5s;
    white-space: nowrap;
}

.dropdown-menu-item-link2 {
    text-decoration: none;
    color: var(--dark-text);
    transition: all 0.5s;
}

.dropdown-menu-item:hover {
    background-color: var(--dark-barnav-dropdown-selected);
    border-radius: 10px;
    transition: all 0.2s;
    cursor: pointer;
}

.dropdown-desktop {
    display: block;
}

/****************************************************/
/* Dropdown Mobile */

.dropdown-mobile {
    display: none;
}

.mobile-main-dropdown {
    font-size: 60px;
}

/****************************************************************************************************/
/*Responsive Mobile*/

@media (max-width: 768px) {
    .dropdown-desktop {
        display: none;
    }

    .dropdown-mobile {
        display: block;
    }

    .dropdown-mobile .dropdown-menu {
        position: fixed;
        top: 10%;
        left: -3%;
        width: 100%;
        height: 100%;
        background-color: var(--dark-main);
        padding: 10px;
        overflow-y: auto;
        transition: transform 0.3s ease-out;
        transform: translateY(-100%);

    }

    @keyframes slideInRight {
        from {
            transform: translateX(100%);
        }

        to {
            transform: translateX(0);
        }
    }

    @keyframes slideOutRight {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(100%);
        }
    }

    .dropdown-mobile .dropdown-menu.init {
        display: none;
    }

    .dropdown-mobile .dropdown-menu.show {
        transform: translateY(0);
        top: 8vh;
        border-top: 10px solid var(--dark-barnav);
        border-radius: 0;
        animation: slideInRight 0.5s ease forwards;
    }

    .dropdown-mobile .dropdown-menu.hide {
        animation: slideOutRight 0.5s ease forwards;
    }

    .dropdown-mobile-title {
        font-size: 1.3em;
        font-style: bold;
        font-weight: bold;
        padding: 25px;
        color: var(--dark-text);
        transition: all 0.5s;
    }

    .mobile-main-dropdown {
        font-size: 2em !important;
        font-family: "JakartaExtraBold", sans-serif;
        font-style: bold;
        font-weight: bold;

        /* text-decoration: underline !important; */
        margin-bottom: 3vh;
    }

    .dropdown-menu-item:hover {
        background-color: none;
    }

    .dropdown-arrow {
        border-top: 2px solid var(--dark-text);
        border-right: 2px solid var(--dark-text);
        border-bottom: none;
    }

    .backbutton-dropdown {
        position: absolute;
        top: 70vh;
        left: 40px;
        width: 50px;
        height: 50px;
        background-color: var(--dark-barnav);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}